import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
// @mui

import {
  Link,
  Stack,
  Select,
  FormHelperText,
  MenuItem,
  Grid,
  CircularProgress,
  InputLabel,
  IconButton,
  InputAdornment,
  TextField,
  Input,
  Checkbox,
  Box,
  Card,
  Typography,
  Modal,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// utils
import { LoadingButton } from '@mui/lab';
import { API_URL, IMAGE_URL } from '../../../utils/constant';

import { uploadImage } from '../../../api/upload';
import { getCategoryList } from '../../../api/category-fip';
import { getSubCatgoryList } from '../../../api/sub-category-fip';

// components

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  maxHeight: '100%',
};

// ----------------------------------------------------------------------

// ShopProductCard.propTypes = {
//     product: PropTypes.object,
// };

export default function AddProductFip({ open, onPopUpClose, loading, onSubmit, initialValues }) {
  const [formData, setFormData] = useState(() => initialValues);
  const [selectedFile, setSelectedFile] = useState(null);

  const [selectedFileSrc, setSelectedFileSrc] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(0);
  const [subCategoryDisabled, setSubCategoryDisabled] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [formError, setFormError] = useState(false);
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleClick = async (event) => {
    event.preventDefault();
    setFormError(false);

    if (formData.name === '') {
      setFormError(true);
      return false;
    }
    if (formData.description !== '') {
      formData.description = null;
    }

    if (formData.price === '') {
      formData.price = null;
    }

    // if(formData.partNumber === '') {
    //   partNumber=null
    // }
    if (formData.price === '') {
      formData.price = null;
    }

    if (formData.hsnCode === '') {
      formData.hsnCode = null;
    }
    if (formData.gst === '') {
      formData.gst = null;
    }
    if (formData.price === '0') {
      formData.price = null;
    }

    if (formData.productType === '') {
      // alert('Please choose product type it is mandatory');
      setFormError(true);
    }

    if (selectedFile) {
      const url = await uploadImage('product-fip', selectedFile);
      formData.imageUrl = `${IMAGE_URL}/${url.data}`;
    }
    setSelectedFileSrc(null);
    onSubmit(formData);
    return true;
  };

  const handleImageUpload = (event) => {
    event.preventDefault();
    setFormError(false);
    const file = event.target.files[0];
    setSelectedFile(file);
    const reader = new FileReader();

    reader.onload = () => {
      const base64Image = reader.result;
      const name = 'imageUrl';
      // Use the base64Image string as needed

      setSelectedFileSrc(base64Image);
      // setFormData((prevFormData) => ({
      //     ...prevFormData,
      //     [name]: base64Image,
      // }));
    };
    reader.readAsDataURL(file);
  };

  // const handleImageUpload = (event) => {
  //   event.preventDefault();
  //   setFormError(false);
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  //   const reader = new FileReader();

  //   reader.onload = () => {
  //     const base64Image = null;
  //     const name = 'imageUrl';
  //     // Use the base64Image string as needed

  //     setSelectedFileSrc(base64Image);
  //     // setFormData((prevFormData) => ({
  //     //     ...prevFormData,
  //     //     [name]: base64Image,
  //     // }));
  //   };
  //   reader.readAsDataURL(file);
  // };

  // const { open } = props;
  const handleClose = () => {
    onPopUpClose();
  };
  const handleCategoryChange = async (event) => {
    event.preventDefault();
    setSubCategoryDisabled(true);
    setSelectedCategory(event.target.value);

    await getSubCatgoryListData(event.target.value);
    const name = 'subCategoryId';
    // Use the base64Image string as needed
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: 0,
    }));
  };
  const getSubCatgoryListData = async (categoryId) => {
    try {
      const data = {
        categoryIds: [categoryId],
        page: 1,
        limit: 100,
        keyword: '',
      };
      const categoryRes = await getSubCatgoryList(data);
      const { subCategoryList, count } = categoryRes.data;
      setSubCategoryList(subCategoryList);
      // const name = 'subCategoryId'
      // // Use the base64Image string as needed

      // setFormData((prevFormData) => ({
      //     ...prevFormData,
      //     [name]: base64Image,
      // }));
      setSubCategoryDisabled(false);
    } catch (error) {
      if (error?.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }
  };

  const getCategoryListData = async () => {
    try {
      const data = {
        page: 1,
        limit: 100,
        keyword: '',
      };
      const categoryRes = await getCategoryList(data);
      const { categoryList, count } = categoryRes.data;

      setCategoryList(categoryList);
    } catch (error) {
      if (error?.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }
  };

  useEffect(() => {
    getCategoryListData();
    if (initialValues.categoryId !== 0) {
      getSubCatgoryListData(initialValues.categoryId);
    }
    setFormData(initialValues);
  }, [initialValues]);

  console.log(formData);

  return (
    <Grid container>
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <h2 style={{ marginLeft: '10px' }}>Add Product</h2>
              <IconButton onClick={handleClose}>X</IconButton>
            </Stack>
            <form id="loginForm" onSubmit={handleClick}>
              <Stack spacing={3}>
                <TextField
                  name="name"
                  label="Product Name"
                  value={formData.name}
                  onChange={handleFormChange}
                  required
                />
                {/* <TextField
                  name="description"
                  value={formData.description}
                  onChange={handleFormChange}
                  label="Descripion"
                /> */}
                <TextField
                  type="text"
                  name="price"
                  label="Price"
                  value={Number(formData.price)}
                  onChange={handleFormChange}
                />
                <TextField
                  type="text"
                  name="partNumber"
                  label="Part Number"
                  value={formData.partNumber}
                  onChange={handleFormChange}
                  required
                />
                <TextField
                  type="text"
                  name="hsnCode"
                  label="HSN"
                  value={formData.hsnCode}
                  onChange={handleFormChange}
                />
                <TextField
                  type="text"
                  name="gst"
                  label="GST(in %)"
                  value={Number(formData.gst) || 0}
                  onChange={handleFormChange}
                />
                {/* number: '',
    noOfCly: "",

    element: '',
    dv: '',
    nozzle: '',
    vehicle: '',
    pressure: '',
    piston: '',
    cp: '',
    ds: '',
    roller: '',
    valveset: '',
    engine: '',
    oePartNumber: '',
    oem: '', */}

                {/* <TextField
                  type="text"
                  name="number"
                  label="Number"
                  value={formData.number}
                  onChange={handleFormChange}
                />
                <TextField
                  type="text"
                  name="noOfCly"
                  label="NoOfCly"
                  value={formData.noOfCly}
                  onChange={handleFormChange}
                />

                <TextField
                  type="text"
                  name="element"
                  label="Element"
                  value={formData.element}
                  onChange={handleFormChange}
                />

                <TextField type="text" name="dv" label="DV" value={formData.dv} onChange={handleFormChange} />

                <TextField
                  type="text"
                  name="nozzle"
                  label="Nozzle"
                  value={formData.nozzle}
                  onChange={handleFormChange}
                />
                <TextField
                  type="text"
                  name="vehicle"
                  label="Vehicle"
                  value={formData.vehicle}
                  onChange={handleFormChange}
                />

                <TextField
                  type="text"
                  name="pressure"
                  label="Pressure"
                  value={formData.pressure}
                  onChange={handleFormChange}
                />

                <TextField
                  type="text"
                  name="piston"
                  label="Piston"
                  value={formData.piston}
                  onChange={handleFormChange}
                />

                <TextField type="text" name="cp" label="CP" value={formData.cp} onChange={handleFormChange} />

                <TextField type="text" name="ds" label="DS" value={formData.ds} onChange={handleFormChange} />

                <TextField
                  type="text"
                  name="roller"
                  label="Roller"
                  value={formData.roller}
                  onChange={handleFormChange}
                />
                <TextField
                  type="text"
                  name="valveset"
                  label="ValveSet"
                  value={formData.valveset}
                  onChange={handleFormChange}
                />
                <TextField
                  type="text"
                  name="engine"
                  label="Engine"
                  value={formData.engine}
                  onChange={handleFormChange}
                />
                <TextField
                  type="text"
                  name="oePartNumber"
                  label="OePartNumber"
                  value={formData.oePartNumber}
                  onChange={handleFormChange}
                />
                <TextField type="text" name="oem" label="Oem" value={formData.oem} onChange={handleFormChange} /> */}

                {/* --------------------------------addbefore------------------- */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="productType"
                  value={formData.productType}
                  label="Age"
                  onChange={(event) => {
                    handleFormChange(event);
                  }}
                  required
                >
                  <MenuItem value={'default'}>--- Select Product Type ---</MenuItem>
                  <MenuItem value={'free'}>Free</MenuItem>
                  <MenuItem value={'paid'}>Paid</MenuItem>
                </Select>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="categoryId"
                  value={formData.categoryId}
                  label=""
                  onChange={(event) => {
                    handleFormChange(event);
                    handleCategoryChange(event);
                  }}
                  required
                >
                  {' '}
                  <MenuItem value={0}>--- Select Category ---</MenuItem>
                  {categoryList
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="subCategoryId"
                  disabled={subCategoryDisabled}
                  value={formData.subCategoryId}
                  label="asdad"
                  onChange={(event) => {
                    handleFormChange(event);
                  }}
                  required
                >
                  {' '}
                  <MenuItem value={0}>--- Select Sub Category ---</MenuItem>
                  {subCategoryList
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
                <TextField type="file" name="imageUrl" accept="image/jpeg, image/png" onChange={handleImageUpload} />
                <FormHelperText dark>image must 500x500</FormHelperText>
                <img height={100} width={100} src={selectedFileSrc || formData.imageUrl} alt="" />

                {/* {formError && <FormHelperText error>This field is required.</FormHelperText>} */}

                <Stack alignItems="center" spacing={2}>
                  {loading ? (
                    <Stack alignItems="center" justifyContent="center">
                      <CircularProgress />
                    </Stack>
                  ) : (
                    <LoadingButton fullWidth size="large" type="submit" variant="contained">
                      {formData.id !== '' ? 'Update Product' : 'Add Product'}
                    </LoadingButton>
                  )}
                </Stack>
              </Stack>
            </form>
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
}
