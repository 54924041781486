// import PropTypes from 'prop-types'; import { useState, useEffect } from 'react';

// // @mui


// import { Link, Stack, Select, FormHelperText, MenuItem, CircularProgress, InputLabel, IconButton, InputAdornment, TextField, Input, Checkbox, Box, Card, Typography, Modal } from '@mui/material';
// import { styled } from '@mui/material/styles';

// // utils
// import { LoadingButton } from '@mui/lab';
// import { API_URL } from '../../../utils/constant';

// import { uploadImage } from '../../../api/upload';
// import { getPlanList } from '../../../api/plan';
// import { getUsersListForDropDown } from '../../../api/users';


// // components

// // ----------------------------------------------------------------------
// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     width: '50%',
//     transform: 'translate(-50%, -50%)',
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
//     overflow: 'auto',
//     maxHeight: '100%',
// };

// // ----------------------------------------------------------------------

// // ShopProductCard.propTypes = {
// //     product: PropTypes.object,
// // };
// const intialError={
//     isValid:false,
//     errors:{}
// }
// export default function AddMembership({ open, onPopUpClose, loading, onSubmit, initialValues }) {
//     const [formData, setFormData] = useState(() => initialValues);
//     const [selectedFile, setSelectedFile] = useState(null);
//     const [planList, setPlanList] = useState([]);
//     const [userList, setUserList] = useState([]);

//     const [selectedFileSrc, setSelectedFileSrc] = useState(null);
//     const [searchedKey, setSearchedKey] = useState(0);
//     const [formError, setFormError] = useState({
//         isValid:false,
//         errors:{}
//     });


//     const onblurEffect=(event)=>{
//         validationFormData();

//     }
//     const handleFormChange = (event) => {
//         const { name, value } = event.target;
//         setFormData((prevFormData) => ({
//             ...prevFormData,
//             [name]: value,
//         }));
//          };

//          const handleSearchKeyChange =async  (event) => {
//             setSearchedKey(event.target.value);
        

//             await getUserList(event.target.value)

//          }
//     const validationFormData= async ()=>{
//         if (formData.transactionId ==='' || formData.transactionId.length <3 ) {
            
//             const transactionId= 'transactionId'
//             setFormError((prevFormData) => ({
//                 isValid:false,
//                 errors:{
//                     ...prevFormData.errors,
//                     [transactionId]: 'transactionId is not correct'
//                 }
//             }));
//             return false;
//         }
//         setFormError({
//             isValid:true,
//             errors:{

//             }
//         })
// return true
//     }
//     const handleClick = async (event) => {
//         event.preventDefault();
//         validationFormData();

//         if (selectedFile) {
//             const url = await uploadImage('category', selectedFile)
//             formData.imageUrl = url.data
//         }
//         setSelectedFileSrc(null)
//         onSubmit(formData)
//         return true;
//     };
//     const handleImageUpload = (event) => {
//         event.preventDefault();
//         const file = event.target.files[0];
//         setSelectedFile(file);
//         const reader = new FileReader();

//         reader.onload = () => {
//             const base64Image = reader.result;
//             // const name = 'imageUrl'
//             // Use the base64Image string as needed
    
//             setSelectedFileSrc(base64Image);
//             // setFormData((prevFormData) => ({
//             //     ...prevFormData,
//             //     [name]: base64Image,
//             // }));
//         };
//         reader.readAsDataURL(file);


//     };


//     // const { open } = props;
//     const handleClose = () => {
//         onPopUpClose()
//     }
//     const getCategoryListData = async () => {
//         try {
//             const data = {
//                 page: 1,
//                 limit: 100,
//                 keyword: ''
//             }
//             const categoryRes = await getPlanList(data);
//             const { planList, count } = categoryRes.data;

//             setPlanList(planList)
//         } catch (error) {
//             if(error?.response.status ===401){
//                 localStorage.clear();
//                 window.location.reload();
//             }
//             toast.error(error?.response?.data?.error || 'Something went wrong')

//         }
//     };
//     const getUserList = async (keyword='') => {
//         try {
    

//             const categoryRes = await getUsersListForDropDown(keyword);
//             const { userList, count } = categoryRes.data;

//             setUserList(userList)
//         } catch (error) {
//             if(error?.response.status ===401){
//                 localStorage.clear();
//                 window.location.reload();
//             }
//             toast.error(error?.response?.data?.error || 'Something went wrong')

//         }
//     };
//     useEffect(() => {
//         setFormData(initialValues)
//         getCategoryListData()
//         getUserList()
//     }, [initialValues])
//     return (
//         <Modal
//             open={open}
//             onClose={handleClose}
//             aria-labelledby="modal-modal-title"
//             aria-describedby="modal-modal-description"


//         >
//             <Box sx={style} >
//                 <Stack direction="row" justifyContent="space-between" alignItems="center">
//                     <h2 style={{ marginLeft: '10px' }}>Add Membership</h2>
//                     <IconButton onClick={handleClose}>
//                         X
//                     </IconButton>
//                 </Stack>
//                 <form id='loginForm' onSubmit={handleClick} >

//                     <Stack spacing={3}>
//                     <Select
//                             labelId="demo-simple-select-label"
//                             id="demo-simple-select"
//                             name='userId'
//                             value={formData.userId}
//                             label="Age"
//                             onChange={(event) => { handleFormChange(event) }}
//                         >

// {/* <TextField placeholder='--- Select User ---' label="Transaction Id" required /> */}
// <TextField  value={searchedKey} onChange={ handleSearchKeyChange} fullWidth/>
  
                      
//                             <MenuItem value={0}>--- Select User ---</MenuItem>
//                             {userList.slice().reverse().map((item, index) => (
//                                 <MenuItem key={index} value={item.id}>{item.phone}</MenuItem>
//                             ))
//                             }
//                         </Select>
//                         <Select
//                             labelId="demo-simple-select-label"
//                             id="demo-simple-select"
//                             name='planId'
//                             value={formData.planId}
//                             label="Age"
//                             onChange={(event) => { handleFormChange(event) }}
//                         >
                            
//                             <MenuItem value={0}>--- Select Plan ---</MenuItem>
//                             {planList.map((item, index) => (
//                                 <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
//                             ))
//                             }
//                         </Select>
//                         <TextField name="transactionId" label="Transaction Id" value={formData.name} onChange={handleFormChange} onBlur={onblurEffect} required />
                      
                 
                

//                         <Stack alignItems="center" spacing={2}>
//                             {loading ? (
//                                 <Stack alignItems="center" justifyContent="center">
//                                     <CircularProgress />
//                                 </Stack>
//                             ) : (
//                                 <LoadingButton disabled={!formError.isValid} fullWidth size="large" type="submit" variant="contained">
//                                     {formData.id !== '' ? 'Update Membership' : 'Add Membership'}
//                                 </LoadingButton>
//                             )}
//                         </Stack>


//                     </Stack>
//                 </form>
//             </Box>
//         </Modal>
//     );
// }



// import PropTypes from 'prop-types';
// import { useState, useEffect } from 'react';
// import { Box, Stack, Select, MenuItem, CircularProgress, TextField, IconButton, Button, Modal } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { LoadingButton } from '@mui/lab';
// import { API_URL } from '../../../utils/constant';
// import { uploadImage } from '../../../api/upload';
// import { getPlanList } from '../../../api/plan';
// import { getUsersListForDropDown } from '../../../api/users';
// import { updateMembershipStatus } from '../../../api/membership'; // Import the update function

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     width: '50%',
//     transform: 'translate(-50%, -50%)',
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
//     overflow: 'auto',
//     maxHeight: '100%',
// };

// const initialError = {
//     isValid: true,
//     errors: {}
// };

// export default function AddMembership({ open, onPopUpClose, loading, onSubmit, initialValues }) {
//     const [formData, setFormData] = useState(initialValues);
//     const [selectedFile, setSelectedFile] = useState(null);
//     const [planList, setPlanList] = useState([]);
//     const [userList, setUserList] = useState([]);
//     const [selectedFileSrc, setSelectedFileSrc] = useState(null);
//     const [searchedKey, setSearchedKey] = useState('');
//     const [formError, setFormError] = useState(initialError);
//     const [mode, setMode] = useState('add');

//     useEffect(() => {
//         setFormData(initialValues);
//         setMode(initialValues.id ? 'edit' : 'add');
//         getCategoryListData();
//         getUserList();
//     }, [initialValues]);

//     const onBlurEffect = async () => {
//         await validationFormData();
//     };

//     const handleFormChange = (event) => {
//         const { name, value } = event.target;
//         setFormData((prevFormData) => ({
//             ...prevFormData,
//             [name]: value,
//         }));
//     };

//     const handleSearchKeyChange = async (event) => {
//         setSearchedKey(event.target.value);
//         await getUserList(event.target.value);
//     };

//     const validationFormData = async () => {
//         const errors = {};
//         if (!formData.transactionId || formData.transactionId.length < 3) {
//             errors.transactionId = 'Transaction Id is not correct';
//         }
//         setFormError({
//             isValid: Object.keys(errors).length === 0,
//             errors
//         });
//         return Object.keys(errors).length === 0;
//     };

//     const handleClick = async (event) => {
//         event.preventDefault();
//         const isValid = await validationFormData();
//         if (!isValid) return;

//         if (selectedFile) {
//             const url = await uploadImage('category', selectedFile);
//             formData.imageUrl = url.data;
//         }
//         setSelectedFileSrc(null);
//         onSubmit(formData);
//     };

//     const handleImageUpload = (event) => {
//         event.preventDefault();
//         const file = event.target.files[0];
//         setSelectedFile(file);
//         const reader = new FileReader();

//         reader.onload = () => {
//             const base64Image = reader.result;
//             setSelectedFileSrc(base64Image);
//         };
//         reader.readAsDataURL(file);
//     };

//     const handleClose = () => {
//         onPopUpClose();
//     };

//     const getCategoryListData = async () => {
//         try {
//             const data = {
//                 page: 1,
//                 limit: 100,
//                 keyword: ''
//             };
//             const categoryRes = await getPlanList(data);
//             setPlanList(categoryRes.data.planList);
//         } catch (error) {
//             console.error('Error fetching plan list:', error);
//             alert('Failed to load plans');
//         }
//     };

//     const getUserList = async (keyword = '') => {
//         try {
//             const categoryRes = await getUsersListForDropDown(keyword);
//             setUserList(categoryRes.data.userList);
//         } catch (error) {
//             console.error('Error fetching user list:', error);
//             alert('Failed to load users');
//         }
//     };

// const handleUpdateStatus = async (id, newStatus) => {
//   try {
//     await updateMembershipStatus(id, newStatus);
//     await getCategoryListData();
//     onSubmit({ ...formData, status: newStatus }); 
//     onPopUpClose(); 
//   } catch (error) {
//     if (error?.response?.status === 401) {
//       localStorage.clear();
//       window.location.reload();
//     }
//     toast.error(error.message || 'Failed to update membership status');
//   }
// };

//     return (
//         <Modal
//             open={open}
//             onClose={handleClose}
//             aria-labelledby="modal-modal-title"
//             aria-describedby="modal-modal-description"
//         >
//             <Box sx={style}>
//                 <Stack direction="row" justifyContent="space-between" alignItems="center">
//                     <h2 style={{ marginLeft: '10px' }}>{mode === 'edit' ? 'Edit Membership' : 'Add Membership'}</h2>
//                     <IconButton onClick={handleClose}>
//                         X
//                     </IconButton>
//                 </Stack>
//                 {mode === 'edit' ? (
//                     <Stack direction="column" spacing={2} mt={2} justifyContent="center" >
//                         <Button
//                             size="small"
//                             variant={formData.status === 'active' ? 'contained' : 'outlined'}
//                             color="success"
//                             onClick={() => handleUpdateStatus(formData.id, 'active')}
//                             sx={{
//                                 backgroundColor: formData.status === 'active' ? '#2065D1' : 'white',
//                                 color: formData.status === 'active' ? 'white' : '#2065D1',
//                                 borderColor: '#2065D1',
//                                 '&:hover': {
//                                     backgroundColor: formData.status === 'active' ? 'darkblue' : '#2065D1',
//                                     borderColor: '#2065D1',
//                                     color: 'white',
//                                 },
//                                 marginBottom: 2,
//                                         fontSize: '1.2rem' 

//                             }}
//                             style={{ padding: '20px 0' }}
//                         >
//                             Active
//                         </Button>
//                         <Button
//                             size="small"
//                             variant={formData.status === 'paused' ? 'contained' : 'outlined'}
//                             color="warning"
//                             onClick={() => handleUpdateStatus(formData.id, 'paused')}
//                             sx={{
//                                 backgroundColor: formData.status === 'active' ? '#2065D1' : 'white',
//                                 color: formData.status === 'active' ? 'white' : '#2065D1',
//                                 borderColor: '#2065D1',
//                                 '&:hover': {
//                                     backgroundColor: formData.status === 'active' ? 'darkblue' : '#2065D1',
//                                     borderColor: '#2065D1',
//                                     color: 'white',
//                                 },
//                                 marginBottom: 2,
//                                         fontSize: '1.2rem' 

//                             }}
//                             style={{ padding: '20px 0' }}

//                         >
//                             Paused
//                         </Button>
//                         <Button
//                             size="small"
//                             variant={formData.status === 'inactive' ? 'contained' : 'outlined'}
//                             color="error"
//                             onClick={() => handleUpdateStatus(formData.id, 'inactive')}
//                             sx={{
//                                 backgroundColor: formData.status === 'active' ? '#2065D1' : 'white',
//                                 color: formData.status === 'active' ? 'white' : '#2065D1',
//                                 borderColor: '#2065D1',
//                                 '&:hover': {
//                                     backgroundColor: formData.status === 'active' ? 'darkblue' : '#2065D1',
//                                     borderColor: '#2065D1',
//                                     color: 'white',
//                                 },
//                                 marginBottom: 2,
//                                         fontSize: '1.2rem' 
//                             }}
//                             style={{ padding: '20px 0' }}

//                         >
//                             Inactive
//                         </Button>
//                     </Stack>
//                 ) : (
//                     <form id='loginForm' onSubmit={handleClick}>
//                         <Stack spacing={3}>
//                             <Select
//                                 labelId="demo-simple-select-label"
//                                 id="demo-simple-select"
//                                 name='userId'
//                                 value={formData.userId}
//                                 label="User"
//                                 onChange={handleFormChange}
//                             >
//                                 <TextField value={searchedKey} onChange={handleSearchKeyChange} fullWidth />
//                                 <MenuItem value={0}>--- Select User ---</MenuItem>
//                                 {userList.slice().reverse().map((item, index) => (
//                                     <MenuItem key={index} value={item.id}>{item.phone}</MenuItem>
//                                 ))}
//                             </Select>
//                             <Select
//                                 labelId="demo-simple-select-label"
//                                 id="demo-simple-select"
//                                 name='planId'
//                                 value={formData.planId}
//                                 label="Plan"
//                                 onChange={handleFormChange}
//                             >
//                                 <MenuItem value={0}>--- Select Plan ---</MenuItem>
//                                 {planList.map((item, index) => (
//                                     <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
//                                 ))}
//                             </Select>
//                             <TextField name="transactionId" label="Transaction Id" value={formData.transactionId} onChange={handleFormChange} onBlur={onBlurEffect} required />
//                             <Stack alignItems="center" spacing={2}>
//                                 {loading ? (
//                                     <Stack alignItems="center" justifyContent="center">
//                                         <CircularProgress />
//                                     </Stack>
//                                 ) : (
//                                     <LoadingButton disabled={!formError.isValid} fullWidth size="large" type="submit" variant="contained">
//                                         {formData.id !== '' ? 'Update Membership' : 'Add Membership'}
//                                     </LoadingButton>
//                                 )}
//                             </Stack>
//                         </Stack>
//                     </form>
//                 )}
//             </Box>
//         </Modal>
//     );
// }


import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Box, Stack, Select, MenuItem, CircularProgress, TextField, IconButton, Button, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { uploadImage } from '../../../api/upload';
import { getPlanList } from '../../../api/plan';
import { getUsersListForDropDown } from '../../../api/users';
import { updateMembershipStatus } from '../../../api/membership'; // Import the update function

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '100%',
};

const initialError = {
    isValid: true,
    errors: {}
};

export default function AddMembership({ open, onPopUpClose, loading, onSubmit, initialValues }) {
    const [formData, setFormData] = useState(initialValues);
    const [selectedFile, setSelectedFile] = useState(null);
    const [planList, setPlanList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [selectedFileSrc, setSelectedFileSrc] = useState(null);
    const [searchedKey, setSearchedKey] = useState('');
    const [formError, setFormError] = useState(initialError);
    const [mode, setMode] = useState('add');
    const onBlurEffect = async () => {
        await validationFormData();
    };
    useEffect(() => {
    console.log("Membership status is:", formData.status);
}, [formData.status]);

    useEffect(() => {
        console.log("Initial Values Received:", initialValues); 
        setFormData(initialValues);
        setMode(initialValues.id ? 'edit' : 'add');
        getCategoryListData();
        getUserList();
    }, [initialValues]);

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
console.log("//////////////////////////////////////////////////",formData.status);
    const handleSearchKeyChange = async (event) => {
        setSearchedKey(event.target.value);
        await getUserList(event.target.value);
    };

    const validationFormData = async () => {
        const errors = {};
        if (!formData.transactionId || formData.transactionId.length < 3) {
            errors.transactionId = 'Transaction Id is not correct';
        }
        setFormError({
            isValid: Object.keys(errors).length === 0,
            errors
        });
        return Object.keys(errors).length === 0;
    };

const handleClick = async (event) => {
    event.preventDefault();
    const isValid = await validationFormData();
    if (!isValid) return;

    if (selectedFile) {
        const url = await uploadImage('category', selectedFile);
        formData.imageUrl = url.data;
    }
    setSelectedFileSrc(null);
    
    console.log("Submitting Form Data:", formData);  // Log form data before submitting
    onSubmit(formData);
};


const handleUpdateStatus = async (id, newStatus) => {
    console.log("Current Status:", formData.status);  // Check current status
    console.log("New Status to be Sent:", newStatus);  // Check new status
    console.log("Formatted Status to be Sent:", newStatus.toUpperCase());  // Confirm the formatted status

    try {
        // Make sure the status is formatted correctly
        const formattedStatus = newStatus.toUpperCase();
        const response = await updateMembershipStatus(id, formattedStatus);
        console.log("API Response:", response);  // Log the API response

        // Update formData with the new status
        onSubmit({ ...formData, status: newStatus });
        onPopUpClose();
    } catch (error) {
        if (error?.response?.status === 401) {
            localStorage.clear();
            window.location.reload();
        }
        console.error("Error updating membership status:", error);
        toast.error(error.message || 'Failed to update membership status');
    }
};



    const handleImageUpload = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        setSelectedFile(file);
        const reader = new FileReader();

        reader.onload = () => {
            const base64Image = reader.result;
            setSelectedFileSrc(base64Image);
        };
        reader.readAsDataURL(file);
    };

    const handleClose = () => {
        onPopUpClose();
    };

    const getCategoryListData = async () => {
        try {
            const data = {
                page: 1,
                limit: 100,
                keyword: ''
            };
            const categoryRes = await getPlanList(data);
            setPlanList(categoryRes.data.planList);
        } catch (error) {
            console.error('Error fetching plan list:', error);
            toast.error('Failed to load plans');
        }
    };

    const getUserList = async (keyword = '') => {
        try {
            const categoryRes = await getUsersListForDropDown(keyword);
            setUserList(categoryRes.data.userList);
        } catch (error) {
            console.error('Error fetching user list:', error);
            toast.error('Failed to load users');
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <h2 style={{ marginLeft: '10px' }}>{mode === 'edit' ? 'Edit Membership' : 'Add Membership'}</h2>
                    <IconButton onClick={handleClose}>
                        X
                    </IconButton>
                </Stack>
                {mode === 'edit' ? (
                    <Stack direction="column" spacing={2} mt={2} justifyContent="center" >
                        {/* Active Button */}
                        <Button
                            size="small"
                            variant={formData.status === 'active' ? 'contained' : 'outlined'}
                            color="success"
                            onClick={() => handleUpdateStatus(formData.id, 'active')}
                            sx={{
                                backgroundColor: formData.status === 'active' ? '#2065D1' : 'white',
                                color: formData.status === 'active' ? 'white' : '#2065D1',
                                borderColor: '#2065D1',
                                '&:hover': {
                                    backgroundColor: formData.status === 'active' ? 'darkblue' : '#2065D1',
                                    borderColor: '#2065D1',
                                    color: 'white',
                                },
                                marginBottom: 2,
                                fontSize: '1.2rem'
                            }}
                            style={{ padding: '20px 0' }}
                        >
                            Active
                        </Button>

                        {/* Dynamic Pause/Resume Button */}
<Button
    size="small"
    variant={formData.status === 'PAUSED' ? 'contained' : 'outlined'}
    color="warning"
    onClick={() => handleUpdateStatus(formData.id, formData.status === 'PAUSED' ? 'RESUME' : 'PAUSED')}
    sx={{
        backgroundColor: 'white',
        color: '#2065D1',
        border: `1px solid ${formData.status === 'PAUSED' ? '#2065D1' : '#2065D1'}`, 
        '&:hover': {
            backgroundColor: '#2065D1',
            borderColor: '#2065D1',
            color: 'white',
        },
        marginBottom: 2,
        fontSize: '1.2rem'
    }}
    style={{ padding: '20px 0' }}
>
    {formData.status === 'PAUSED' ? 'Resume' : 'Pause'}
</Button>


                        {/* Inactive Button */}
                        <Button
                            size="small"
                            variant={formData.status === 'inactive' ? 'contained' : 'outlined'}
                            color="error"
                            onClick={() => handleUpdateStatus(formData.id, 'inactive')}
                            sx={{
                                backgroundColor: formData.status === 'inactive' ? '#2065D1' : 'white',
                                color: formData.status === 'inactive' ? 'white' : '#2065D1',
                                borderColor: '#2065D1',
                                '&:hover': {
                                    backgroundColor: formData.status === 'inactive' ? 'darkblue' : '#2065D1',
                                    borderColor: '#2065D1',
                                    color: 'white',
                                },
                                marginBottom: 2,
                                fontSize: '1.2rem'
                            }}
                            style={{ padding: '20px 0' }}
                        >
                            Inactive
                        </Button>
                    </Stack>
                ) : (
                    <form id='loginForm' onSubmit={handleClick}>
                        <Stack spacing={3}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name='userId'
                                value={formData.userId}
                                label="User"
                                onChange={handleFormChange}
                            >
                                <TextField value={searchedKey} onChange={handleSearchKeyChange} fullWidth />
                                <MenuItem value={0}>--- Select User ---</MenuItem>
                                {userList.slice().reverse().map((item, index) => (
                                    <MenuItem key={index} value={item.id}>{item.phone}</MenuItem>
                                ))}
                            </Select>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name='planId'
                                value={formData.planId}
                                label="Plan"
                                onChange={handleFormChange}
                            >
                                <MenuItem value={0}>--- Select Plan ---</MenuItem>
                                {planList.map((item, index) => (
                                    <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                            <TextField name="transactionId" onBlur={onBlurEffect} label="Transaction Id" onChange={handleFormChange} value={formData.transactionId} />
                            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
                                Add Membership
                            </LoadingButton>
                        </Stack>
                    </form>
                )}
            </Box>
        </Modal>
    );
}
