// import PropTypes from 'prop-types';
// import { useState, useEffect } from 'react';

// // @mui

// import {
//   Link,
//   Stack,
//   Select,
//   FormHelperText,
//   MenuItem,
//   Grid,
//   CircularProgress,
//   InputLabel,
//   IconButton,
//   InputAdornment,
//   TextField,
//   Input,
//   Checkbox,
//   Box,
//   Card,
//   Typography,
//   Modal,
// } from '@mui/material';
// import { styled } from '@mui/material/styles';

// // utils
// import { LoadingButton } from '@mui/lab';
// import { API_URL } from '../../../utils/constant';

// import { uploadImage } from '../../../api/upload';
// import { getCategoryList } from '../../../api/category';
// import { getSubCatgoryList } from '../../../api/sub-category';
// import { getBrandList } from '../../../api/brand';
// // import { brand } from 'src/_mock/brand';

// // components

// // ----------------------------------------------------------------------
// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   width: '50%',
//   transform: 'translate(-50%, -50%)',
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
//   overflow: 'auto',
//   maxHeight: '100%',
// };

// // ----------------------------------------------------------------------

// // ShopProductCard.propTypes = {
// //     product: PropTypes.object,
// // };

// export default function AddProduct({ open, onPopUpClose, loading, onSubmit, initialValues }) {
//   const [formData, setFormData] = useState(() => initialValues);
//   const [selectedFile, setSelectedFile] = useState(null);

//   const [selectedFileSrc, setSelectedFileSrc] = useState(null);
//   const [selectedBrand, setSelectedBrand] = useState(0);
//   const [subCategoryDisabled, setSubCategoryDisabled] = useState(true);
//   const [categoryList, setCategoryList] = useState([]);
//   const [brandList, setBrandList] = useState([]);
//   const [subCategoryList, setSubCategoryList] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState(0);
//   const [formError, setFormError] = useState(false);
//   const handleFormChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));
//   };

//   const handleClick = async (event) => {
//     event.preventDefault();
//     setFormError(false);

//     if (formData.name === '') {
//       setFormError(true);
//       return false;
//     }
//     if (formData.description === '') {
//       formData.description = 'null';
//     }
//     if (formData.price === '') {
//       formData.price = 'null';
//     }
//     if (formData.barandId === '') {
//       formData.brand = '4';
//     }
//     if (formData.gst === '') {
//       formData.gst = 0;
//     }

//     // if(formData.category === ''){

//     //   setFormError(true);
//     // }

//     if (selectedFile) {
//       const url = await uploadImage('product', selectedFile);
//       formData.imageUrl = `${API_URL}/images/${url.data}`;
//     }
//     setSelectedFileSrc(null);
//     onSubmit(formData);
//     return true;
//   };
//   const handleImageUpload = (event) => {
//     event.preventDefault();
//     setFormError(false);
//     const file = event.target.files[0];
//     setSelectedFile(file);
//     const reader = new FileReader();

//     reader.onload = () => {
//       const base64Image = reader.result;
//       // const name = 'imageUrl'
//       // Use the base64Image string as needed

//       setSelectedFileSrc(base64Image);
//       // setFormData((prevFormData) => ({
//       //     ...prevFormData,
//       //     [name]: base64Image,
//       // }));
//     };
//     reader.readAsDataURL(file);
//   };

//   // const { open } = props;
//   const handleClose = () => {
//     onPopUpClose();
//   };
//   const handleCategoryChange = async (event) => {
//     event.preventDefault();
//     setSubCategoryDisabled(true);
//     setSelectedCategory(event.target.value);

//     await getSubCatgoryListData(event.target.value);
//     const name = 'subCategoryId';
//     // Use the base64Image string as needed
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: 0,
//     }));
//   };
//   const getSubCatgoryListData = async (categoryId) => {
//     try {
//       const data = {
//         categoryIds: [categoryId],
//         page: 1,
//         limit: 100,
//         keyword: '',
//       };
//       const categoryRes = await getSubCatgoryList(data);
//       const { subCategoryList, count } = categoryRes.data;
//       setSubCategoryList(subCategoryList);
//       // const name = 'subCategoryId'
//       // // Use the base64Image string as needed

//       // setFormData((prevFormData) => ({
//       //     ...prevFormData,
//       //     [name]: base64Image,
//       // }));
//       setSubCategoryDisabled(false);
//     } catch (error) {
//       if (error?.response.status === 401) {
//         localStorage.clear();
//         window.location.reload();
//       }
//       alert(error?.response?.data?.error || 'Something went wrong');
//     }
//   };

//   const getCategoryListData = async () => {
//     try {
//       const data = {
//         page: 1,
//         limit: 100,
//         keyword: '',
//       };
//       const categoryRes = await getCategoryList(data);
//       const { categoryList, count } = categoryRes.data;

//       setCategoryList(categoryList);
//     } catch (error) {
//       if (error?.response.status === 401) {
//         localStorage.clear();
//         window.location.reload();
//       }
//       alert(error?.response?.data?.error || 'Something went wrong');
//     }
//   };
//   const getBrandListData = async () => {
//     try {
//       const data = {
//         page: 1,
//         limit: 100,
//         keyword: '',
//       };
//       const categoryRes = await getBrandList(data);
//       const { brandList, count } = categoryRes.data;

//       setBrandList(brandList);
//     } catch (error) {
//       if (error?.response.status === 401) {
//         localStorage.clear();
//         window.location.reload();
//       }
//       alert(error?.response?.data?.error || 'Something went wrong');
//     }
//   };
//   useEffect(() => {
//     getCategoryListData();
//     getBrandListData();
//     if (initialValues.categoryId !== 0) {
//       getSubCatgoryListData(initialValues.categoryId);
//     }
//     setFormData(initialValues);
//   }, [initialValues]);

//   return (
//     <>
//       <Grid container>
//         <Grid item xs={12} sm={8} md={6} lg={4}>
//           <Modal
//             open={open}
//             onClose={handleClose}
//             aria-labelledby="modal-modal-title"
//             aria-describedby="modal-modal-description"
//           >
//             <Box sx={style}>
//               <Stack direction="row" justifyContent="space-between" alignItems="center">
//                 <h2 style={{ marginLeft: '10px' }}>Add Product</h2>
//                 <IconButton onClick={handleClose}>X</IconButton>
//               </Stack>
//               <form id="loginForm" onSubmit={handleClick}>
//                 <Stack spacing={3}>
//                   <TextField
//                     name="name"
//                     label="Product Name"
//                     value={formData.name}
//                     onChange={handleFormChange}
//                     required
//                   />
//                   <TextField
//                     name="description"
//                     value={formData.description}
//                     onChange={handleFormChange}
//                     label="Descripion"
//                   />
//                   <TextField
//                     type="text"
//                     name="price"
//                     label="Price"
//                     value={Number(formData.price) || 0}
//                     onChange={handleFormChange}
//                     required
//                   />
//                   <TextField
//                     type="text"
//                     name="partNumber"
//                     label="Part Number"
//                     value={formData.partNumber}
//                     onChange={handleFormChange}
//                     required
//                   />
//                   <TextField
//                     type="text"
//                     name="hsnCode"
//                     label="HSN"
//                     value={formData.hsnCode}
//                     onChange={handleFormChange}
//                   />
//                   <TextField
//                     type="text"
//                     name="gst"
//                     label="GST(in %)"
//                     value={Number(formData.gst)}
//                     onChange={handleFormChange}
//                   />

//                   <Select
//                     labelId="demo-simple-select-label"
//                     id="demo-simple-select"
//                     name="productType"
//                     value={formData.productType}
//                     label="Age"
//                     onChange={(event) => {
//                       handleFormChange(event);
//                     }}
//                     required
//                   >
//                     <MenuItem value={'default'}>--- Select Product Type ---</MenuItem>
//                     <MenuItem value={'free'}>Free</MenuItem>
//                     <MenuItem value={'paid'}>Paid</MenuItem>
//                   </Select>
//                   {/* <Select
//                     labelId="demo-simple-select-label"
//                     id="demo-simple-select"
//                     name="brandId"
//                     value={formData.brandId}
//                     label="Age"
//                     onChange={(event) => {
//                       handleFormChange(event);
//                     }}
//                   >
//                     <MenuItem value={0}>--- Select Brand ---</MenuItem>
//                     {brandList
//                       .slice()
//                       .sort((a, b) => a.name.localeCompare(b.name))
//                       .map((item) => (
//                         <MenuItem key={item.id} value={item.id}>
//                           {item.name}
//                         </MenuItem>
//                       ))}
//                   </Select> */}
//                   <Select
//                     labelId="demo-simple-select-label"
//                     id="demo-simple-select"
//                     name="categoryId"
//                     value={formData.categoryId}
//                     label=""
//                     onChange={(event) => {
//                       handleFormChange(event);
//                       handleCategoryChange(event);
//                     }}
//                   >
//                     {' '}
//                     <MenuItem value={0}>--- Select Category ---</MenuItem>
//                     {categoryList
//                       .slice()
//                       .sort((a, b) => a.name.localeCompare(b.name))
//                       .map((item) => (
//                         <MenuItem key={item.id} value={item.id}>
//                           {item.name}
//                         </MenuItem>
//                       ))}
//                   </Select>
//                   <Select
//                     labelId="demo-simple-select-label"
//                     id="demo-simple-select"
//                     name="subCategoryId"
//                     disabled={subCategoryDisabled}
//                     value={formData.subCategoryId}
//                     label="asdad"
//                     onChange={(event) => {
//                       handleFormChange(event);
//                     }}
//                   >
//                     <MenuItem value={0}>--- Select Sub Category ---</MenuItem>
//                     {subCategoryList
//                       .slice()
//                       .sort((a, b) => a.name.localeCompare(b.name))
//                       .map((item) => (
//                         <MenuItem key={item.id} value={item.id}>
//                           {item.name}
//                         </MenuItem>
//                       ))}
//                   </Select>
//                   <TextField type="file" name="imageUrl" accept="image/jpeg, image/png" onChange={handleImageUpload} />
//                   <FormHelperText dark>image must 500x500</FormHelperText>
//                   <img height={100} width={100} src={selectedFileSrc || formData.imageUrl} alt="" />

//                   {formError && <FormHelperText error>This field is required.</FormHelperText>}

//                   <Stack alignItems="center" spacing={2}>
//                     {loading ? (
//                       <Stack alignItems="center" justifyContent="center">
//                         <CircularProgress />
//                       </Stack>
//                     ) : (
//                       <LoadingButton fullWidth size="large" type="submit" variant="contained">
//                         {formData.id !== '' ? 'Update Product' : 'Add Product'}
//                       </LoadingButton>
//                     )}
//                   </Stack>
//                 </Stack>
//               </form>
//             </Box>
//           </Modal>
//         </Grid>
//       </Grid>
//     </>
//   );
// }

// --------------------------------------------------------------------------

import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
// @mui

import {
  Link,
  Stack,
  Select,
  FormHelperText,
  MenuItem,
  Grid,
  CircularProgress,
  InputLabel,
  IconButton,
  InputAdornment,
  TextField,
  Input,
  Checkbox,
  Box,
  Card,
  Typography,
  Modal,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// utils
import { LoadingButton } from '@mui/lab';
import { API_URL, IMAGE_URL } from '../../../utils/constant';

import { uploadImage } from '../../../api/upload';
import { getCategoryList } from '../../../api/category';
import { getSubCatgoryList } from '../../../api/sub-category';
import { getBrandList } from '../../../api/brand';

// components

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  maxHeight: '100%',
};

// ----------------------------------------------------------------------

// ShopProductCard.propTypes = {
//     product: PropTypes.object,
// };

export default function AddProduct({ open, onPopUpClose, loading, onSubmit, initialValues }) {
  const [formData, setFormData] = useState(() => initialValues);
  const [selectedFile, setSelectedFile] = useState(null);

  const [selectedFileSrc, setSelectedFileSrc] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(0);
  const [subCategoryDisabled, setSubCategoryDisabled] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [formError, setFormError] = useState(false);
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleClick = async (event) => {
    event.preventDefault();
    setFormError(false);

    // if (formData.name === '') {
    //   setFormError(true);
    //   return false;
    // }
    // if (formData.brandId === '') {
    //   formData.brandId = 4;
    // }

    if (formData.description === '') {
      formData.description = 'null';
    }

    if (formData.gst === '') {
      formData.gst = '0';
    }
    if (formData.hsnCode === '') {
      formData.hsnCode = 'null';
    }


    if (formData.category === '') {
      setFormError(true);
      return false;
    }
    if (formData.imageUrl === '') {
      formData.imageUrl = 'null';
    }
    if (formData.price === '') {
      formData.price = "10";

    }
    if (formData.subCategoryId === '') {
      setFormError(true);
      return false;
    }

    formData.brandId = 57;

    // if (formData.brandId === '') {
    //   formData.brandId = 57;

    // }
    if (selectedFile) {
      const url = await uploadImage('product', selectedFile);
      formData.imageUrl = `${IMAGE_URL}/${url.data}`;
    }
    setSelectedFileSrc(null);
    onSubmit(formData);
    return true;
  };
  const handleImageUpload = (event) => {
    event.preventDefault();
    setFormError(false);
    const file = event.target.files[0];
    setSelectedFile(file);
    const reader = new FileReader();

    reader.onload = () => {
      const base64Image = reader.result;
      // const name = 'imageUrl'
      // Use the base64Image string as needed

      setSelectedFileSrc(base64Image);
      // setFormData((prevFormData) => ({
      //     ...prevFormData,
      //     [name]: base64Image,
      // }));
    };
    reader.readAsDataURL(file);
  };

  // const { open } = props;
  const handleClose = () => {
    onPopUpClose();
  };
  const handleCategoryChange = async (event) => {
    event.preventDefault();
    setSubCategoryDisabled(true);
    setSelectedCategory(event.target.value);

    await getSubCatgoryListData(event.target.value);
    const name = 'subCategoryId';
    // Use the base64Image string as needed
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: 0,
    }));
  };
  const getSubCatgoryListData = async (categoryId) => {
    try {
      const data = {
        categoryIds: [categoryId],
        page: 1,
        limit: 100,
        keyword: '',
      };
      const categoryRes = await getSubCatgoryList(data);
      const { subCategoryList, count } = categoryRes.data;
      setSubCategoryList(subCategoryList);
      // const name = 'subCategoryId'
      // // Use the base64Image string as needed

      // setFormData((prevFormData) => ({
      //     ...prevFormData,
      //     [name]: base64Image,
      // }));
      setSubCategoryDisabled(false);
    } catch (error) {
      if (error?.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }
  };

  const getCategoryListData = async () => {
    try {
      const data = {
        page: 1,
        limit: 100,
        keyword: '',
      };
      const categoryRes = await getCategoryList(data);
      const { categoryList, count } = categoryRes.data;

      setCategoryList(categoryList);
    } catch (error) {
      if (error?.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }
  };
  const getBrandListData = async () => {
    try {
      const data = {
        page: 1,
        limit: 100,
        keyword: '',
      };
      const categoryRes = await getBrandList(data);
      const { brandList, count } = categoryRes.data;

      setBrandList(brandList);
    } catch (error) {
      if (error?.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }
  };
  useEffect(() => {
    getCategoryListData();
    getBrandListData();
    if (initialValues.categoryId !== 0) {
      getSubCatgoryListData(initialValues.categoryId);
    }
    setFormData(initialValues);
  }, [initialValues]);

  return (
    <Grid container>
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <h2 style={{ marginLeft: '10px' }}>Add Product</h2>
              <IconButton onClick={handleClose}>X</IconButton>
            </Stack>
            <form id="loginForm" onSubmit={handleClick}>
              <Stack spacing={3}>
                <TextField name="name" label="Part Number" value={formData.name} onChange={handleFormChange} />
                {/* <TextField
                  name="description"
                  value={formData.description}
                  onChange={handleFormChange}
                  label="Descripion"
                /> */}
                {/* <TextField
                  type="text"
                  name="price"
                  label="Price"
                  value={Number(formData.price) || 0}
                  onChange={handleFormChange}
                /> */}
                {/* <TextField
                  type="text"
                  name="partNumber"
                  label="Part Number"
                  value={formData.partNumber}
                  onChange={handleFormChange}
                  required
                /> */}
                {/* <TextField
                  type="text"
                  name="hsnCode"
                  label="HSN"
                  value={formData.hsnCode}
                  onChange={handleFormChange}
                /> */}
                {/* <TextField type="text" name="gst" label="GST(in %)" value={formData.gst} onChange={handleFormChange} /> */}

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="productType"
                  value={formData.productType}
                  label="Age"
                  onChange={(event) => {
                    handleFormChange(event);
                  }}
                  required
                >
                  <MenuItem value={'default'}>--- Select Product Type ---</MenuItem>
                  <MenuItem value={'free'}>Free</MenuItem>
                  <MenuItem value={'paid'}>Paid</MenuItem>
                </Select>
                {/* <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="brandId"
                  value={formData.brandId}
                  label="Age"
                  onChange={(event) => {
                    handleFormChange(event);
                  }}
                >
                  <MenuItem value={0}>--- Select Brand ---</MenuItem>
                  {brandList
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="categoryId"
                  value={formData.categoryId}
                  label=""
                  onChange={(event) => {
                    handleFormChange(event);
                    handleCategoryChange(event);
                  }}
                  required
                >
                  {' '}
                  <MenuItem value={0}>--- Select Category ---</MenuItem>
                  {categoryList
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="subCategoryId"
                  disabled={subCategoryDisabled}
                  value={formData.subCategoryId}
                  label="asdad"
                  onChange={(event) => {
                    handleFormChange(event);
                  }}
                >
                  {' '}
                  <MenuItem value={0}>--- Select Sub Category ---</MenuItem>
                  {subCategoryList
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
                <TextField type="file" name="imageUrl" accept="image/jpeg, image/png" onChange={handleImageUpload} />
                <FormHelperText dark>image must 500x500</FormHelperText>
                <img height={100} width={100} src={selectedFileSrc || formData.imageUrl} alt="" />

                {formError && <FormHelperText error>This field is required.</FormHelperText>}

                <Stack alignItems="center" spacing={2}>
                  {loading ? (
                    <Stack alignItems="center" justifyContent="center">
                      <CircularProgress />
                    </Stack>
                  ) : (
                    <LoadingButton fullWidth size="large" type="submit" variant="contained">
                      {formData.id !== '' ? 'Update Product' : 'Add Product'}
                    </LoadingButton>
                  )}
                </Stack>
              </Stack>
            </form>
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
}
