import PropTypes from 'prop-types'; import { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
// @mui

import { Link, Stack, Select, FormHelperText, MenuItem, CircularProgress, InputLabel, IconButton, InputAdornment, TextField, Input, Checkbox, Box, Card, Typography, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';

// utils
import { LoadingButton } from '@mui/lab';
import { API_URL } from '../../../utils/constant';

import { uploadImage } from '../../../api/upload';


// components

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '100%',
};

// ----------------------------------------------------------------------

// ShopProductCard.propTypes = {
//     product: PropTypes.object,
// };

export default function AddBrand({ open, onPopUpClose, loading, onSubmit, initialValues }) {
    const [formData, setFormData] = useState(() => initialValues);
    const [selectedFile, setSelectedFile] = useState(null);

    const [selectedFileSrc, setSelectedFileSrc] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [formError, setFormError] = useState(false);
    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const handleClick = async (event) => {
        event.preventDefault(); setFormError(false);

        if (formData.name === '' || formData.description === '') {
            setFormError(true);
            return false;
        }

        if (selectedFile) {
            if(!selectedFile.name.endsWith('.png') && !selectedFile.name.endsWith('.jpg') && !selectedFile.name.endsWith('.jpeg')&& !selectedFile.name.endsWith('.webp') && !selectedFile.name.endsWith('.gif')&& !selectedFile.name.endsWith('.svg')){
                toast.error('Please upload valid image file')

                return true;
            }
            const url = await uploadImage('brand', selectedFile)
            formData.imageUrl = `${API_URL}/images/${url.data}`
        }
        setSelectedFileSrc(null)
        onSubmit(formData)
        return true;
    };
    const handleImageUpload = (event) => {
        event.preventDefault(); setFormError(false);
        const file = event.target.files[0];
        setSelectedFile(file);
        const reader = new FileReader();

        reader.onload = () => {
            const base64Image = reader.result;
            // const name = 'imageUrl'
            // Use the base64Image string as needed
            setSelectedFileSrc(base64Image);
            // setFormData((prevFormData) => ({
            //     ...prevFormData,
            //     [name]: base64Image,
            // }));
        };
        reader.readAsDataURL(file);


    };


    // const { open } = props;
    const handleClose = () => {
        onPopUpClose()
    }
    useEffect(() => {
        setFormData(initialValues)
    }, [initialValues])
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"


        >
            <Box sx={style} >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <h2 style={{ marginLeft: '10px' }}>Add Brand</h2>
                    <IconButton onClick={handleClose}>
                        X
                    </IconButton>
                </Stack>
                <form id='loginForm' onSubmit={handleClick} >

                    <Stack spacing={3}>
                        <TextField name="name" label="Brand Name" value={formData.name} onChange={handleFormChange} required />
                        <TextField name="description" label="Description" value={formData.description} onChange={handleFormChange} />
                        <TextField type='file' name="imageUrl" accept="image/jpeg, image/png"
    onChange={handleImageUpload} />
                        <img height={100} width={100} src={selectedFileSrc || formData.imageUrl} alt="" />

                        {formError && (
                            <FormHelperText error>
                                This field is required.
                            </FormHelperText>
                        )}


                        {formError && (
                            <FormHelperText error>
                                This field is required.
                            </FormHelperText>
                        )}

                        <Stack alignItems="center" spacing={2}>
                            {loading ? (
                                <Stack alignItems="center" justifyContent="center">
                                    <CircularProgress />
                                </Stack>
                            ) : (
                                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                                    {formData.id !== '' ? 'Update Brand' : 'Add Brand'}
                                </LoadingButton>
                            )}
                        </Stack>


                    </Stack>
                </form>
            </Box>
        </Modal>
    );
}
