import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Step } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
// hooks
import useResponsive from '../hooks/useResponsive';
import Footer from './Footer';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';



// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage({ setIsAuthenticated }) {
  const mdUp = useResponsive('up', 'md');
  const [step, setStep] = useState(1); // Step 1: Enter password, Step 2: Enter OTP
  const [email, setEmail] = useState('');

  return (
    <>
      <Helmet>
        <title> Login | Fuel-Engine </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              {step === 1 ? 'Sign in to Fuel-Engine' : 'OTP Verification'}
            </Typography>

            {/* <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Link variant="subtitle2">Get started</Link>
            </Typography> */}

            <Stack direction="row" spacing={2}>
              {/* <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
              </Button> */}
            </Stack>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {step === 1 ? 'Sign In' : `Enter OTP code sent to ${email}`}
              </Typography>
            </Divider>

         

            <LoginForm
              step={step}
              setStep={setStep}
              email={email}
              setEmail={setEmail}
              setIsAuthenticated={setIsAuthenticated}
            />
            {/* <Container sx={{ textAlign: 'center', marginTop: '60px' }}> <Typography variant="h6" gutterBottom>
              @Powered by Yellowbit Labs
            </Typography></Container> */}
          </StyledContent>

          <Footer />
        </Container>
      </StyledRoot>
      {/* <BottomNavigation>

     
        <Typography variant="h6" gutterBottom>
          @Powered by Yellowbit Labs
        </Typography>
      </BottomNavigation> */}
    </>
  );
}
