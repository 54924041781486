import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, CircularProgress, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { login, resendOTP, verifyOtp } from '../../../api/auth';

export default function LoginForm({ step, setStep, email, setEmail }) {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(30); // Initial timer value (e.g., 30 seconds)
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      if (step === 1) {
        // Step 1: Send OTP
        const formElement = document.querySelector('#loginForm');
        const formData = new FormData(formElement);
        const formDataJSON = Object.fromEntries(formData);

        setEmail(formDataJSON.email);
        setPassword(formDataJSON.password);

        await login({ email: formDataJSON.email, password: formDataJSON.password }).then((res) => {
          console.log(res?.data?.message);
          toast.success(res?.data?.message || 'Otp sent successfully');
        });
        setTimer(30); // Reset timer value
        setIsResendDisabled(true);
        setStep(2); // Move to OTP entry step
      } else if (step === 2) {
        // Step 2: Submit OTP
        const otpResponse = await verifyOtp({ email, otp });

        console.log(otpResponse, 'generateOtp');

        toast.success(otpResponse?.data?.message || 'Logged in successfully');

        const jwtToken = otpResponse.data.jwt;
        const refreshToken = otpResponse.data.refreshToken;

        localStorage.setItem('token', jwtToken); // Store JWT token
        localStorage.setItem('refreshToken', refreshToken); // Store refresh token

        navigate('/admin/dashboard/app', { replace: true });
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.error || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown); // Cleanup interval
    }

    setIsResendDisabled(false);
    return undefined; // Explicit return for consistency
  }, [timer]);

  const handleResend = async () => {
    try {
      if (isResendDisabled) return;
      setOtp('');
      const response = await resendOTP({ email });
      toast.success(response?.data?.message || 'OTP sent successfully!');

      setTimer(30); // Reset timer value
      setIsResendDisabled(true);
    } catch (error) {
      toast.error(error?.response?.data?.error || 'Failed to resend OTP. Please try again.');
    }
  };

  return (
    <>
      <form id="loginForm" onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {step === 1 ? (
            <>
              <TextField name="email" label="Email" />
              <TextField
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </>
          ) : (
            <TextField
              name="otp"
              label="OTP"
              type="number"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              InputProps={{
                endAdornment: (
                  <Button
                    size="medium"
                    variant="text"
                    onClick={handleResend}
                    disabled={isResendDisabled}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {isResendDisabled ? `Resend (${timer}s)` : 'Resend'}
                  </Button>
                ),
              }}
            />
          )}
        </Stack>

        {loading ? (
          <Stack alignItems="center" justifyContent="center" sx={{ my: 2 }}>
            <CircularProgress />
          </Stack>
        ) : (
          <LoadingButton fullWidth size="large" type="submit" sx={{ my: 2 }} variant="contained">
            {step === 1 ? 'Login' : 'Submit'}
          </LoadingButton>
        )}
      </form>
    </>
  );
}
