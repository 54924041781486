import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { API_URL } from '../utils/constant';
import Footer from './Footer';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { getBrandList } from '../api/brand';
// import { AddCategory, CategoryListHead, CategoryListToolBar } from '../sections/@dashboard/category';
import { MembershipListHead, MembershipListToolbar, AddMembership } from '../sections/@dashboard/memberships';

// mock
import { getMembershipList, deleteMembership, addMembership, updateMembershipStatus } from '../api/membership';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Membership ID', alignRight: false },
  { id: 'username', label: 'Name', alignRight: false },
  { id: 'Email', label: 'Email', alignRight: false },
  { id: 'mobileNumber', label: 'Mobile Number', alignRight: false },
  { id: 'Subscription', label: 'Subscription', alignRight: false },
  { id: 'Subscription Cycle', label: 'Subscription Cycle', alignRight: false },
  // { id: 'expiresAt', label: 'expires at', alignRight: false },
  { id: 'TransactionId', label: 'TransactionId', alignRight: false },
  // { id: 'planName', label: 'Plan Name', alignRight: false },
  { id: 'RemainingDays', label: 'Remaining Days', alignRight: false },
  { id: 'Status', label: 'Status', alignRight: false },
  // { id: 'editsubscriptionstatus', label: 'Edit Subscription', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
const initialValues = {
  id: '',
  userId: 0,
  planId: 0,
  transactionId: '',
};
export default function Memberships() {
  const [activeTotal, setActiveTotal] = useState(0);
  const [nonActiveTotal, setNonActiveTotal] = useState(0);
  const [statusFilter, setStatusFilter] = useState('all'); 
  const [open, setOpen] = useState(null);
  const [openAddPopUp, setAddPopUp] = useState(false);
  const [isAddProductLoading, setIsAddProductLoading] = useState(false);

  const [total, setTotal] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [categoryToBeUpdated, setCategoryToBeUpdated] = useState({
    id: '',
    userId: 0,
    planId: 0,
    transactionId: '',
  });

  const handleOpenMenu = (event, id) => {
    const category = categoryList.find((brand) => brand.id === id);
    setCategoryToBeUpdated(category);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelecteds = USERLIST.map((n) => n.name);
      // setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
const handleStatusUpdate = (updatedMembership) => {
  // Update the state with the new membership status
  setCategoryList((prevList) =>
    prevList.map((membership) =>
      membership.id === updatedMembership.id ? updatedMembership : membership
    )
  );
};
  const handleSubmit = async (data) => {
    try {
      setIsAddProductLoading(true);
      if (data.id !== '') {
        // await updateCategory(data.id, data)
        // const index = category.categoryList.findIndex(category => category.id === data.id)
        // category.categoryList[index] = data;0
      await updateMembershipStatus(data.id, data.status);
      handleStatusUpdate(data); 
      } else {
        delete data.id;
        await addMembership(data);
        // data.id = category.categoryList.length + 1;
        // data.imageurl = null;
        // category.categoryList.push(data);
        // category.count = category.categoryList.length
      }
      setCategoryToBeUpdated({
        id: '',
        userId: 0,
        planId: 0,
        transactionId: '',
      });
      await getCategoryListData();
      setIsAddProductLoading(false);
      setAddPopUp(false);
      setOpen(null);
    } catch (error) {
      setCategoryToBeUpdated({
        id: '',
        userId: 0,
        planId: 0,
        transactionId: '',
      });
      setIsAddProductLoading(false);

      setAddPopUp(false);
      setOpen(null);
      if (error?.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const handleDelete = async (event) => {
    if (categoryToBeUpdated.id !== '') {
      await deleteMembership(categoryToBeUpdated.id);
    }
    await getCategoryListData();
    setOpen(null);
  };
const handleStatusFilter = (status) => {
    setStatusFilter(status);
    setPage(0); 
};
const getCategoryListData = async () => {
    try {
        const data = {
            page: page + 1,
            limit: rowsPerPage,
            keyword: filterName,
            status: statusFilter,
        };
        const response = await getMembershipList(data);
        const { planMembershipList, count, activeCount, nonActiveCount } = response.data;
        console.log('////////////////////////', planMembershipList);
        setActiveTotal(activeCount);
        setNonActiveTotal(nonActiveCount);
        setTotal(count);
        setCategoryList(planMembershipList);
    } catch (error) {
        if (error?.response?.status === 401) {
            localStorage.clear();
            window.location.reload();
        }
        toast.error(error?.response?.data?.error || 'Something went wrong');
    }
};



  useEffect(() => {
    getCategoryListData();
  }, [page, rowsPerPage, filterName, statusFilter]);
console.log('Current status filter:', statusFilter);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categoryList.length) : 0;


  const isNotFound = !categoryList.length && !!filterName;
const calculateRemainingDays = (expiresAt) => {
  const today = new Date();
  const expiryDate = new Date(expiresAt);
  const timeDifference = expiryDate - today;
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysRemaining > 0 ? daysRemaining : "Not Active";
};

const handleUpdateStatus = async (id, newStatus) => {
  try {
    await updateMembershipStatus(id, newStatus);
    await getCategoryListData();
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    toast.error(error.message || 'Failed to update membership status');
  }
};




  return (
    <>
      <Helmet>
        <title> Memberships </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Memberships
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setCategoryToBeUpdated(initialValues);
              setAddPopUp(true);
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Membership
          </Button>
        </Stack>
        <Stack direction="row" spacing={2} mb={3}>
          <Button
            variant={statusFilter === 'active' ? 'contained' : 'outlined'}
            onClick={() => handleStatusFilter('active')}
          >
            Active
          </Button>
          <Button
            variant={statusFilter === 'non-active' ? 'contained' : 'outlined'}
            onClick={() => handleStatusFilter('non-active')}
          >
            Non-Active
          </Button>
          <Button
            variant={statusFilter === 'all' ? 'contained' : 'outlined'}
            onClick={() => handleStatusFilter('all')}
          >
            All
          </Button>
        </Stack>
        <Card>
          <MembershipListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MembershipListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={total}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
<TableBody>
  {categoryList
 .filter((row) => {
            if (statusFilter === 'all') return true;
            if (statusFilter === 'non-active') return row.status === 'PAUSED' || row.status === 'INACTIVE';
            return row.status.toLowerCase() === statusFilter.toLowerCase();
        })
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row) => {
      const { id, plan, user, startDate, expiresAt, transactionId, status } = row;
      const isActive = status.toUpperCase() === 'RESUME' || status.toLowerCase() === 'active';
      const displayStatus = status.toUpperCase() === 'RESUME' ? 'ACTIVE' : status;
      const remainingDays = isActive ? calculateRemainingDays(expiresAt) : null;
      return (
        <TableRow hover key={id} tabIndex={-1} role="checkbox">
          <TableCell align="left">
            <Typography variant="subtitle2" noWrap>
              {id}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography variant="subtitle2" noWrap>
              {user.name}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography variant="subtitle2" noWrap>
              {user.email}
            </Typography>
          </TableCell>
                    <TableCell align="left">{user.phone}</TableCell>
          <TableCell align="left">         <Typography variant="subtitle2" noWrap>
              {plan.name}
            </Typography></TableCell>
                      <TableCell align="left" sx={{minWidth: '225px'}}>
            {/* <Typography variant="subtitle2" noWrap> */}
            <b>Start date: </b> {new Date(startDate).toDateString()} <br/><b>End date: </b> {new Date(expiresAt).toDateString()}
            {/* </Typography> */}
            </TableCell>
          <TableCell align="left">{transactionId}</TableCell>
                    <TableCell align="center">
            {isActive ? (
              // <Button
              //   variant="contained"
              //   color="primary"
              //   sx={{ borderRadius: '50%', minWidth: 32, height: 32, fontSize: '0.75rem' }}
              //   title="Remaining Days"
              // >
              //   {remainingDays}
              // </Button>
              <Typography variant="body2" color="textSecondary" sx={{ minWidth: '130px', padding: '5px 5px', borderRadius: '20px', border: '1px solid #000' }}>
              {remainingDays}
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary" sx={{ minWidth: '130px', padding: '5px 5px', borderRadius: '20px', border: '1px solid #000' }}>
                Not Active
              </Typography>
              //               <Button
              //   variant="contained"
              //   color="primary"
              //   sx={{ borderRadius: '50%', minWidth: 32, height: 59, fontSize: '0.75rem' }}
              //   title="Remaining Days"
              // >
              // Not Active
              // </Button>
            )}
          </TableCell>

          {/* <TableCell align="left">{new Date(expiresAt).toDateString()}</TableCell> */}

          <TableCell align="center">
          {/* <Button
          variant="contained"
          color={isActive ? 'success' : 'error'}
          sx={{ borderRadius: '50%', minWidth: 32, height: 32, fontSize: '0.75rem' }}
          title="Status"
          >
          </Button> */}
          <Typography variant="body2" color="textSecondary" sx={{ padding: '5px 10px', borderRadius: '20px', border: '1px solid #000' }}  >
                            {/* {isActive ? 'Active' : 'Inactive'} */}
                            {/* {status} */}
                            {displayStatus}
          </Typography>
          </TableCell>
       {/* <TableCell align="center">
  <Button
    size="small" 
    variant={status === 'active' ? 'contained' : 'outlined'}
    color="success"
    onClick={() => handleUpdateStatus(id, 'active')}
    sx={{
      backgroundColor: status === 'active' ? '#87CEEB' : 'white',
      color: status === 'active' ? 'white' : '#87CEEB',
      borderColor: '#87CEEB', 
      '&:hover': {
        backgroundColor: status === 'active' ? 'darkblue' : 'lightblue',
        borderColor: '#00BFFF',
        color: 'white',
      },
      marginBottom: 2
    }}  >
    Active
  </Button>
  <Button
    size="small"
    variant={status === 'paused' ? 'contained' : 'outlined'}
    color="warning"
    onClick={() => handleUpdateStatus(id, 'paused')}
    sx={{
      backgroundColor: status === 'active' ? '#87CEEB' : 'white',
      color: status === 'active' ? 'white' : '#87CEEB',
      borderColor: '#87CEEB', 
      '&:hover': {
        backgroundColor: status === 'active' ? '#87CEEB' : 'lightblue',
        borderColor: '#00BFFF',
        color: 'white',
      },
      marginBottom: 2
    }}   >
    Paused
  </Button>
  <Button
    size="small"
    variant={status === 'inactive' ? 'contained' : 'outlined'}
    color="error"
    onClick={() => handleUpdateStatus(id, 'inactive')}
    sx={{
      backgroundColor: status === 'active' ? '#87CEEB' : 'white',
      color: status === 'active' ? 'white' : '#87CEEB',
      borderColor: '#87CEEB', 
      '&:hover': {
        backgroundColor: status === 'active' ? 'darkblue' : 'lightblue',
        borderColor: '#00BFFF',
        color: 'white',
      },
      marginBottom: 2
    }}   >
    Inactive
  </Button>
         </TableCell>  */}

          <TableCell align="right">
            <IconButton
              color="inherit"
              onClick={(event) => handleOpenMenu(event, id)}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    })}
</TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

<TablePagination
    rowsPerPageOptions={[5, 10, 20, 30, 50]}
    component="div"
    count={statusFilter === 'active' ? activeTotal :
    statusFilter === 'non-active' ? nonActiveTotal : total}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
/>
        </Card>
      </Container>
      <AddMembership
        open={openAddPopUp}
        onPopUpClose={() => setAddPopUp(false)}
        loading={isAddProductLoading}
        onSubmit={handleSubmit}
        initialValues={categoryToBeUpdated}
      />

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
          <MenuItem onClick={() => setAddPopUp(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem sx={{ color: 'error.main' }} onClick={handleDelete}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <Container sx={{ mt: 10 }}>
        <Footer />
      </Container>
    </>
  );
}
