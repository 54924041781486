// import { Helmet } from 'react-helmet-async';
// import { filter, initial } from 'lodash';
// import { sentenceCase } from 'change-case';
// import { useState, useEffect } from 'react';

// // @mui
// import {
//   Card,
//   Table,
//   Stack,
//   Paper,
//   Avatar,
//   Button,
//   Popover,
//   Checkbox,
//   TableRow,
//   MenuItem,
//   TableBody,
//   TableCell,
//   Container,
//   Typography,
//   IconButton,
//   TableContainer,
//   TablePagination,
// } from '@mui/material';
// import { API_URL } from '../utils/constant';
// import Footer from './Footer';

// import { AddProduct, CategoryListHead, CategoryListToolBar, ExcelImport } from '../sections/@dashboard/products';

// // components
// import Label from '../components/label';
// import Iconify from '../components/iconify';
// import Scrollbar from '../components/scrollbar';
// // sections

// // mock
// import USERLIST from '../_mock/user';
// import { getProductsList, createProducts, updateProducts, deleteProducts } from '../api/products';

// // ----------------------------------------------------------------------

// const TABLE_HEAD = [
//   { id: 'id', label: 'Product ID', alignRight: false },
//   { id: 'name', label: 'Name', alignRight: false },
//   // { id: 'description', label: 'Description', alignRight: false },
//   // { id: 'price', label: 'price', alignRight: false },
//   { id: 'imageurl', label: 'Image', alignRight: false },
//   { id: 'productType', label: 'type', alignRight: false },
//   // { id: 'hsn', label: 'HSN', alignRight: false },
//   { id: 'partNumber', label: 'Part Number', alignRight: false },
//   // { id: 'gst', label: 'GST(in %)', alignRight: false },
//   // { id: 'brand', label: 'Brand', alignRight: false },
//   { id: 'category', label: 'Category', alignRight: false },
//   { id: 'sub-category', label: 'Sub Category', alignRight: false },

//   { id: '' },
// ];

// // ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }
// const initialValues = {
//   id: '',
//   name: '',
//   description: '',
//   productType: 'default',
//   price: '',
//   imageUrl: '',
//   categoryId: 0,
//   hsnCode: '',
//   partNumber: '',
//   gst: '',
//   subCategoryId: 0,
//   brandId: 0,
// };


// // const initialValues = {
// //   name: '',
// //   description: '',
// //   imageUrl: '',
// //   price: 0,
// //   hsnCode: '',
// //   partNumber: '',
// //   gst: '',
// //   productType: 'default',
// //   brandId: 0,
// //   categoryId: 0,
// //   subCategoryId: 0,
// //   vehicle: '',
// //   repairKitPartNumber: '',
// //   nozzle: '',
// //   valveSet: '',
// //   partSet: '',
// //   application: '',
// //   firstColumnName: '',
// //   categoryName: '',
// //   highPressurePump: '',
// //   rail: '',
// //   mainFilterAssy: '',
// //   mainFilterCartridge: '',
// //   preFilterAssy: '',
// //   preFilterCartridge: '',
// //   ecu: '',
// //   referenceNumber: '',
// //   productName: '',
// //   itemFor: '',
// //   category: '',
// //   suitableFor: '',
// //   cri: '',
// //   ah: '',
// //   dnh: '',
// //   rls: '',
// //   mfrNumber: '',
// //   nozzleHolder: '',
// //   nozzlePart: '',
// //   nozzleOpeningPressure: '',
// //   boschNo: '',
// //   stamping: '',
// //   euroVersion: '',
// //   fisKitWithoutNozzle: '',
// //   fisKitWithNozzle: '',
// //   headAndRouter: '',
// //   tpRouter: '',
// //   tpLinerAndBlade: '',
// //   tpBladeAndSpringKit: '',
// //   tpLiner: '',
// //   solenoidSwitch: '',
// //   meteringValve: '',
// //   kitBearing: '',
// //   kitShaftAndBearing: '',
// //   pumpHousing: '',
// //   oilSeal: '',
// //   overhaulKit: '',
// //   miniOHailKit: '',
// //   camRing: '',
// //   rollerAndShowKit: '',
// //   governorArm: '',
// //   potentiometer: '',
// //   camValve: '',
// //   boosterAssembly: '',
// //   injectorAssyNLS: '',
// //   injectorAssembly: '',
// //   injectorBody: '',
// //   filterAssembly: '',
// //   filterHead: '',
// //   filterElement: '',
// //   kitFilterElement: '',
// //   baseFilter: '',
// //   handPrimer: '',
// //   egrSwitch: '',
// // };

// export default function Products() {
//   const [open, setOpen] = useState(null);
//   const [openAddPopUp, setAddPopUp] = useState(false);
//   const [excelImportPopup, setExcelImportPopup] = useState(false);
//   const [isAddProductLoading, setIsAddProductLoading] = useState(false);

//   const [total, setTotal] = useState(0);
//   const [categoryList, setCategoryList] = useState([]);
//   const [page, setPage] = useState(0);

//   const [order, setOrder] = useState('asc');

//   const [selected, setSelected] = useState([]);

//   const [orderBy, setOrderBy] = useState('name');

//   const [filterName, setFilterName] = useState('');

//   const [rowsPerPage, setRowsPerPage] = useState(5);

//   const [categoryToBeUpdated, setCategoryToBeUpdated] = useState({
//     // id: '',
//     name: '',
//     description: '',
//     price: '',
//     productType: 'default',
//     hsnCode: '',
//     partNumber: '',
//     gst: '',
//     imageUrl: '',
//     categoryId: 0,
//     subCategoryId: 0,
//     brandId: 0,
//     // repairKitPartNumber: '',
//     // nozzle: '',
//     // valveSet: '',
//     // partSet: '',
//     // application: '',
//     // firstColumnName: '',
//     // categoryName: '',
//     // highPressurePump: '',
//     // rail: '',
//     // mainFilterAssy: '',
//     // mainFilterCartridge: '',
//     // preFilterAssy: '',
//     // preFilterCartridge: '',
//     // ecu: '',
//     // referenceNumber: '',
//     // productName: '',
//     // itemFor: '',
//     // category: '',
//     // suitableFor: '',
//     // cri: '',
//     // ah: '',
//     // dnh: '',
//     // rls: '',
//     // mfrNumber: '',
//     // nozzleHolder: '',
//     // nozzlePart: '',
//     // nozzleOpeningPressure: '',
//     // boschNo: '',
//     // stamping: '',
//     // euroVersion: '',
//     // fisKitWithoutNozzle: '',
//     // fisKitWithNozzle: '',
//     // headAndRouter: '',
//     // tpRouter: '',
//     // tpLinerAndBlade: '',
//     // tpBladeAndSpringKit: '',
//     // tpLiner: '',
//     // solenoidSwitch: '',
//     // meteringValve: '',
//     // kitBearing: '',
//     // kitShaftAndBearing: '',
//     // pumpHousing: '',
//     // oilSeal: '',
//     // overhaulKit: '',
//     // miniOHailKit: '',
//     // camRing: '',
//     // rollerAndShowKit: '',
//     // governorArm: '',
//     // potentiometer: '',
//     // camValve: '',
//     // boosterAssembly: '',
//     // injectorAssyNLS: '',
//     // injectorAssembly: '',
//     // injectorBody: '',
//     // filterAssembly: '',
//     // filterHead: '',
//     // filterElement: '',
//     // kitFilterElement: '',
//     // baseFilter: '',
//     // handPrimer: '',
//     // egrSwitch: '',
//   });

//   // const handleOpenMenu = (event, id) => {
//   //   const category = categoryList.find((brand) => brand.id === id);

//   //   category.categoryId = category.category.id;
//   //   // category.brandId = category.brand.id;
//   //   category.subCategoryId = category.subCategory.id;

//   //   setCategoryToBeUpdated(category);
//   //   setOpen(event.currentTarget);
//   // };

//   const handleOpenMenu = (event, id) => {
//     const product = categoryList.find((item) => item.id === id);

//     if (product) {
//       const updatedCategory = {
//         id: product.id,
//         name: product.name,
//         description: product.description,
//         productType: product.productType,
//         price: product.price,
//         imageUrl: product.imageUrl,
//         hsnCode: product.hsnCode,
//         partNumber: product.partNumber,
//         gst: product.gst,
//         categoryName: product.categoryName,
//       };

//       if (product.category) {
//         updatedCategory.categoryId = product.category.id;
//       } else {
//         updatedCategory.categoryId = 0;
//       }

//       if (product.subCategory) {
//         updatedCategory.subCategoryId = product.subCategory.id;
//       } else {
//         updatedCategory.subCategoryId = 0;
//       }

//       if (product.brand) {
//         updatedCategory.brandId = product.brand.id;
//       } else {
//         updatedCategory.brandId = 0;
//       }

//       setCategoryToBeUpdated(updatedCategory);

//       setOpen(event.currentTarget);
//     } else {
//       console.error(`Product with ID ${id} not found in categoryList`);
//     }
//   };


//   const handleCloseMenu = () => {
//     setOpen(null);
//   };
//   const handleSubmit = async (data) => {
//     try {
//       setIsAddProductLoading(true);
//       if (data.id !== '') {
//         await updateProducts(data.id, data);
//         // const index = category.categoryList.findIndex(category => category.id === data.id)
//         // category.categoryList[index] = data;
//       } else {
//         delete data.id;
//         await createProducts(data);
//       }
//       setCategoryToBeUpdated({
//         id: '',
//         name: '',
//         description: '',
//         productType: 'default',
//         price: '',
//         hsnCode: '',
//         partNumber: '',
//         gst: '',
//         imageUrl: '',
//         categoryId: 0,
//         subCategoryId: 0,
//         brandId: 4,
//         categoryName: '',
//       });
//       await getCategoryListData();
//       setIsAddProductLoading(false);
//       setAddPopUp(false);
//       setOpen(null);
//     } catch (error) {
//       setCategoryToBeUpdated({
//         id: '',
//         name: '',
//         description: '',
//         productType: 'default',
//         price: '',
//         hsnCode: '',
//         partNumber: '',
//         gst: '',
//         imageUrl: '',
//         categoryId: 0,
//         subCategoryId: 0,
//         brandId: 0,
//         categoryName: '',
//       });
//       setIsAddProductLoading(false);

//       setAddPopUp(false);
//       setOpen(null);
//       if (error?.response.status === 401) {
//         localStorage.clear();
//         window.location.reload();
//       }
//       alert(error?.response?.data?.error || 'Something went wrong');
//     }
//   };

//   const handleRequestSort = (event, property) => {
//     const isAsc = orderBy === property && order === 'asc';
//     setOrder(isAsc ? 'desc' : 'asc');
//     setOrderBy(property);
//   };

//   const handleSelectAllClick = (event) => {
//     if (event.target.checked) {
//       const newSelecteds = USERLIST.map((n) => n.name);
//       setSelected(newSelecteds);
//       return;
//     }
//     setSelected([]);
//   };

//   const handleClick = (event, name) => {
//     const selectedIndex = selected.indexOf(name);
//     let newSelected = [];
//     if (selectedIndex === -1) {
//       newSelected = newSelected.concat(selected, name);
//     } else if (selectedIndex === 0) {
//       newSelected = newSelected.concat(selected.slice(1));
//     } else if (selectedIndex === selected.length - 1) {
//       newSelected = newSelected.concat(selected.slice(0, -1));
//     } else if (selectedIndex > 0) {
//       newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
//     }
//     setSelected(newSelected);
//   };

//   const handleChangePage = async (event, newPage) => {
//     setPage(newPage);
//   };
//   const refreshData = async () => {
//     await getCategoryListData();
//   };

//   const handleChangeRowsPerPage = async (event) => {
//     setPage(0);
//     setRowsPerPage(parseInt(event.target.value, 10));
//   };

//   const handleFilterByName = (event) => {
//     setPage(0);
//     setFilterName(event.target.value);
//   };
//   const handleDelete = async (event) => {
//     if (categoryToBeUpdated.id !== '') {
//       await deleteProducts(categoryToBeUpdated.id);
//     }
//     await getCategoryListData();
//     setOpen(null);
//   };
//   const getCategoryListData = async () => {
//     try {
//       const data = {
//         page: page + 1,
//         limit: rowsPerPage,
//         keyword: filterName,
//       };

//       const categoryRes = await getProductsList(data);
//       const { productList, count } = categoryRes.data;
//       setTotal(count);

//       console.log('Fetched productList:', productList); // Log fetched productList
//       console.log('Total count:', count); // Log total count
//       setCategoryList(productList);
//     } catch (error) {
//       if (error?.response.status === 401) {
//         localStorage.clear();
//         window.location.reload();
//       }
//       alert(error?.response?.data?.error || 'Something went wrong');
//     }
//   };

//   useEffect(() => {
//     getCategoryListData();
//   }, [page, rowsPerPage, filterName]);

//   const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

//   // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

//   const isNotFound = !categoryList.length && !!filterName;

//   return (
//     <>
//       <Helmet>
//         <title> Products </title>
//       </Helmet>

//       <Container>
//         <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
//           <Typography variant="h4" gutterBottom>
//             Products
//           </Typography>
//           <Button
//             variant="contained"
//             onClick={() => {
//               setCategoryToBeUpdated(initialValues);
//               setAddPopUp(true);
//             }}
//             startIcon={<Iconify icon="eva:plus-fill" />}
//           >
//             Add Product
//           </Button>
//         </Stack>

//         <Card>
//           <CategoryListToolBar
//             numSelected={selected.length}
//             filterName={filterName}
//             onFilterName={handleFilterByName}
//             setExcelImportPopupFlag={() => setExcelImportPopup(true)}
//           />

//           <Scrollbar>
//             <TableContainer sx={{ minWidth: 800 }}>
//               <Table>
//                 <CategoryListHead
//                   order={order}
//                   orderBy={orderBy}
//                   headLabel={TABLE_HEAD}
//                   rowCount={total}
//                   numSelected={selected.length}
//                   onRequestSort={handleRequestSort}
//                   onSelectAllClick={handleSelectAllClick}
//                 />
//                 <TableBody>
//                   {categoryList.slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage).map((row) => {
//                     const {
//                       id,
//                       name,
//                       description,
//                       price,
//                       imageUrl,
//                       gst,
//                       hsnCode,
//                       partNumber,
//                       category,
//                       brand,
//                       productType,
//                       subCategory,
//                       categoryName,
//                       nozzle,
//                     } = row;
//                     const selectedUser = selected.indexOf(name) !== -1;
//                     return (
//                       <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
//                         {/* <TableCell padding="checkbox">
//                                                     <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
//                                                 </TableCell> */}

//                         <TableCell align="left">
//                           <Typography variant="subtitle2" noWrap>
//                             {id}
//                           </Typography>
//                         </TableCell>

//                         <TableCell align="left">
//                           <Typography variant="subtitle2" noWrap>
//                             {name}
//                             {/* {nozzle} */}
//                           </Typography>
//                         </TableCell>

//                         {/* <TableCell align="left">{description}</TableCell> */}
//                         {/* <TableCell align="left">{price}</TableCell> */}

//                         <TableCell align="left">
//                           <Stack direction="row" alignItems="center" spacing={2}>
//                             {/* <Avatar alt={name} src={imageUrl} /> */}

//                             <a href={imageUrl} target="_blank" rel="noreferrer">
//                               {' '}
//                               File{' '}
//                             </a>
//                           </Stack>
//                         </TableCell>

//                         <TableCell align="left">{productType}</TableCell>
//                         <TableCell align="left">{partNumber}</TableCell>
//                         {/* <TableCell align="left">{hsnCode}</TableCell>
//                         <TableCell align="left">{gst}</TableCell> */}
//                         {/* <TableCell align="left">
//                           {brand.name} ({brand.id})
//                         </TableCell> */}
//                         <TableCell align="left">
//                           {category?.name && category?.id ? `${category.name} (${category.id})` : category?.name || ""}
//                           {/* {categoryName} */}
//                         </TableCell>
//                         <TableCell align="left">
//                           {subCategory?.name && subCategory?.id ? `(${subCategory.name}) (${subCategory.id})` : subCategory?.name || ""}
//                         </TableCell>


//                         <TableCell align="left">
//                           <IconButton
//                             size="large"
//                             color="inherit"
//                             onClick={(event) => {
//                               handleOpenMenu(event, id);
//                             }}
//                           >
//                             <Iconify icon={'eva:more-vertical-fill'} />
//                           </IconButton>
//                         </TableCell>
//                       </TableRow>
//                     );
//                   })}
//                   {/* {emptyRows > 0 && (
//                     <TableRow style={{ height: 53 * emptyRows }}>
//                       <TableCell colSpan={6} />
//                     </TableRow>
//                   )} */}
//                 </TableBody>

//                 {isNotFound && (
//                   <TableBody>
//                     <TableRow>
//                       <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
//                         <Paper
//                           sx={{
//                             textAlign: 'center',
//                           }}
//                         >
//                           <Typography variant="h6" paragraph>
//                             Not found
//                           </Typography>

//                           <Typography variant="body2">
//                             No results found for &nbsp;
//                             <strong>&quot;{filterName}&quot;</strong>.
//                             <br /> Try checking for typos or using complete words.
//                           </Typography>
//                         </Paper>
//                       </TableCell>
//                     </TableRow>
//                   </TableBody>
//                 )}
//               </Table>
//             </TableContainer>
//           </Scrollbar>

//           <TablePagination
//             rowsPerPageOptions={[5, 10, 20, 30, 50]}
//             component="div"
//             count={total}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onPageChange={handleChangePage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </Card>
//       </Container>

//       <AddProduct
//         open={openAddPopUp}
//         onPopUpClose={() => setAddPopUp(false)}
//         loading={isAddProductLoading}
//         onSubmit={handleSubmit}
//         initialValues={categoryToBeUpdated}
//       />
//       <ExcelImport open={excelImportPopup} onPopUpClose={() => setExcelImportPopup(false)} refreshData={refreshData} />
//       <Popover
//         open={Boolean(open)}
//         anchorEl={open}
//         onClose={handleCloseMenu}
//         anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
//         transformOrigin={{ vertical: 'top', horizontal: 'right' }}
//         PaperProps={{
//           sx: {
//             p: 1,
//             width: 140,
//             '& .MuiMenuItem-root': {
//               px: 1,
//               typography: 'body2',
//               borderRadius: 0.75,
//             },
//           },
//         }}
//       >
//         <MenuItem onClick={() => setAddPopUp(true)}>
//           <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
//           Edit
//         </MenuItem>

//         <MenuItem sx={{ color: 'error.main' }} onClick={handleDelete}>
//           <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
//           Delete
//         </MenuItem>
//       </Popover>
//       <Container sx={{ mt: 10 }}>
//         <Footer />
//       </Container>
//     </>
//   );
// }



// import { Helmet } from 'react-helmet-async';
// import { filter, initial } from 'lodash';
// import { sentenceCase } from 'change-case';
// import { useState, useEffect } from 'react';

// // @mui
// import {
//   Card,
//   Table,
//   Stack,
//   Paper,
//   Avatar,
//   Button,
//   Popover,
//   Checkbox,
//   TableRow,
//   MenuItem,
//   TableBody,
//   TableCell,
//   Container,
//   Typography,
//   IconButton,
//   TableContainer,
//   TablePagination,
// } from '@mui/material';
// import { API_URL } from '../utils/constant';
// import Footer from './Footer';

// import { AddProduct, CategoryListHead, CategoryListToolBar, ExcelImport } from '../sections/@dashboard/products';

// // components
// import Label from '../components/label';
// import Iconify from '../components/iconify';
// import Scrollbar from '../components/scrollbar';
// // sections

// // mock
// import USERLIST from '../_mock/user';
// import { getProductsList, createProducts, updateProducts, deleteProducts } from '../api/products';

// // ----------------------------------------------------------------------

// // const TABLE_HEAD = [
// //   { id: 'id', label: 'Product ID', alignRight: false },
// //   { id: 'SNo', label: 'SNo', alignRight: false },
// //   { id: 'partNumber', label: 'partNumber', alignRight: false },
// //   { id: 'vehicle', label: 'vehicle', alignRight: false },
// //   { id: 'item type', label: 'item Type', alignRight: false },
// //   { id: 'referenceNumber', label: 'referenceNumber', alignRight: false },
// //   { id: 'productName', label: 'productName', alignRight: false },
// //   { id: 'itemFor', label: 'itemFor', alignRight: false },
// //   { id: 'Category', label: 'Category', alignRight: false },
// //   // { id: 'category', label: 'Category', alignRight: false },
// //   { id: 'name', label: 'Name', alignRight: false },
// //   // { id: 'description', label: 'Description', alignRight: false },
// //   // { id: 'price', label: 'price', alignRight: false },
// //   { id: 'imageurl', label: 'Image', alignRight: false },
// //   { id: 'productType', label: 'type', alignRight: false },
// //   // { id: 'hsn', label: 'HSN', alignRight: false },
// //   // { id: 'partNumber', label: 'Part Number', alignRight: false },
// //   // { id: 'gst', label: 'GST(in %)', alignRight: false },
// //   // { id: 'brand', label: 'Brand', alignRight: false },
// //   // { id: 'Type', label: 'Type', alignRight: false },
// //   { id: 'categoryName', label: 'categoryName', alignRight: false },
// //   { id: 'suitableFor', label: 'suitableFor', alignRight: false },
// //   { id: 'sub-category', label: 'Sub Category', alignRight: false },
// //   { id: '' },
// // ];



// // const TABLE_HEAD = [
// //   { id: 'id', label: 'Product ID', alignRight: false },
// //   { id: 'SNo', label: 'SNo', alignRight: false },
// //   { id: 'partNumber', label: 'Part Number', alignRight: false },
// //   { id: 'vehicle', label: 'Vehicle', alignRight: false },
// //   { id: 'itemType', label: 'Item Type', alignRight: false },
// //   { id: 'referenceNumber', label: 'Reference Number', alignRight: false },
// //   { id: 'productName', label: 'Product Name', alignRight: false },
// //   { id: 'itemFor', label: 'Item For', alignRight: false },
// //   { id: 'Category', label: 'Category', alignRight: false },
// //   { id: 'categoryName', label: 'Category Name', alignRight: false },
// //   { id: 'suitableFor', label: 'Suitable For', alignRight: false },
// //   { id: 'subCategoryId', label: 'Sub Category ID', alignRight: false },
// //   { id: 'highPressurePump', label: 'High Pressure Pump', alignRight: false },
// //   { id: 'rail', label: 'Rail', alignRight: false },
// //   { id: 'mainFilterAssy', label: 'Main Filter Assy', alignRight: false },
// //   { id: 'mainFilterCartridge', label: 'Main Filter Cartridge', alignRight: false },
// //   { id: 'preFilterAssy', label: 'Pre Filter Assy', alignRight: false },
// //   { id: 'preFilterCartridge', label: 'Pre Filter Cartridge', alignRight: false },
// //   { id: 'ecu', label: 'ECU', alignRight: false },
// //   { id: 'cri', label: 'CRI', alignRight: false },
// //   { id: 'ah', label: 'AH', alignRight: false },
// //   { id: 'dnh', label: 'DNH', alignRight: false },
// //   { id: 'rls', label: 'RLS', alignRight: false },
// //   { id: 'mfrNumber', label: 'MFR Number', alignRight: false },
// //   { id: 'nozzleHolder', label: 'Nozzle Holder', alignRight: false },
// //   { id: 'nozzlePart', label: 'Nozzle Part', alignRight: false },
// //   { id: 'nozzleOpeningPressure', label: 'Nozzle Opening Pressure', alignRight: false },
// //   { id: 'boschNo', label: 'Bosch No', alignRight: false },
// //   { id: 'stamping', label: 'Stamping', alignRight: false },
// //   { id: 'euroVersion', label: 'Euro Version', alignRight: false },
// //   { id: 'fisKitWithoutNozzle', label: 'FIS Kit Without Nozzle', alignRight: false },
// //   { id: 'fisKitWithNozzle', label: 'FIS Kit With Nozzle', alignRight: false },
// //   { id: 'headAndRouter', label: 'Head and Router', alignRight: false },
// //   { id: 'tpRouter', label: 'TP Router', alignRight: false },
// //   { id: 'tpLinerAndBlade', label: 'TP Liner and Blade', alignRight: false },
// //   { id: 'tpBladeAndSpringKit', label: 'TP Blade and Spring Kit', alignRight: false },
// //   { id: 'tpLiner', label: 'TP Liner', alignRight: false },
// //   { id: 'solenoidSwitch', label: 'Solenoid Switch', alignRight: false },
// //   { id: 'meteringValve', label: 'Metering Valve', alignRight: false },
// //   { id: 'kitBearing', label: 'Kit Bearing', alignRight: false },
// //   { id: 'kitShaftAndBearing', label: 'Kit Shaft and Bearing', alignRight: false },
// //   { id: 'pumpHousing', label: 'Pump Housing', alignRight: false },
// //   { id: 'oilSeal', label: 'Oil Seal', alignRight: false },
// //   { id: 'overhaulKit', label: 'Overhaul Kit', alignRight: false },
// //   { id: 'miniOHailKit', label: 'Mini OHail Kit', alignRight: false },
// //   { id: 'camRing', label: 'Cam Ring', alignRight: false },
// //   { id: 'rollerAndShowKit', label: 'Roller and Show Kit', alignRight: false },
// //   { id: 'governorArm', label: 'Governor Arm', alignRight: false },
// //   { id: 'potentiometer', label: 'Potentiometer', alignRight: false },
// //   { id: 'camValve', label: 'Cam Valve', alignRight: false },
// //   { id: 'boosterAssembly', label: 'Booster Assembly', alignRight: false },
// //   { id: 'injectorAssyNLS', label: 'Injector Assy NLS', alignRight: false },
// //   { id: 'injectorAssembly', label: 'Injector Assembly', alignRight: false },
// //   { id: 'injectorBody', label: 'Injector Body', alignRight: false },
// //   { id: 'filterAssembly', label: 'Filter Assembly', alignRight: false },
// //   { id: 'filterHead', label: 'Filter Head', alignRight: false },
// //   { id: 'filterElement', label: 'Filter Element', alignRight: false },
// //   { id: 'kitFilterElement', label: 'Kit Filter Element', alignRight: false },
// //   { id: 'baseFilter', label: 'Base Filter', alignRight: false },
// //   { id: 'handPrimer', label: 'Hand Primer', alignRight: false },
// //   { id: 'egrSwitch', label: 'EGR Switch', alignRight: false },
// // ];

// const TABLE_HEAD = [
//   { id: 'id', label: 'Product ID', alignRight: false },
//   // { id: 'SNo', label: 'SNo', alignRight: false },
//   { id: 'partNumber', label: 'Part Number', alignRight: false },
//   { id: 'vehicle', label: 'Vehicle', alignRight: false },
//   { id: 'repairKitPartNumber', label: 'Repair Kit Part Number', alignRight: false }, // Added
//   { id: 'imageurl', label: 'Image', alignRight: false },
//   { id: 'Product Type', label: 'Product Type', alignRight: false },
//   { id: 'referenceNumber', label: 'Reference Number', alignRight: false },
//   { id: 'productName', label: 'Product Name', alignRight: false },
//   { id: 'itemFor', label: 'Item For', alignRight: false },
//   { id: 'Category', label: 'Category', alignRight: false },
//   { id: 'categoryName', label: 'CategoryName', alignRight: false },
//   { id: 'suitableFor', label: 'SuitableFor', alignRight: false },
//   { id: 'CategoryId', label: 'CategoryID', alignRight: false },
//   { id: 'subCategoryId', label: 'SubCategoryID', alignRight: false },
//   { id: 'highPressurePump', label: 'HighPressure Pump', alignRight: false },
//   { id: 'rail', label: 'Rail', alignRight: false },
//   { id: 'mainFilterAssy', label: 'MainFilterAssy', alignRight: false },
//   { id: 'mainFilterCartridge', label: 'MainFilterCartridge', alignRight: false },
//   { id: 'preFilterAssy', label: 'PreFilterAssy', alignRight: false },
//   { id: 'preFilterCartridge', label: 'PreFilterCartridge', alignRight: false },
//   { id: 'ecu', label: 'ECU', alignRight: false },
//   { id: 'cri', label: 'CRI', alignRight: false },
//   { id: 'ah', label: 'AH', alignRight: false },
//   { id: 'dnh', label: 'DNH', alignRight: false },
//   { id: 'rls', label: 'RLS', alignRight: false },
//   { id: 'mfrNumber', label: 'MFRNumber', alignRight: false },
//   { id: 'nozzleHolder', label: 'NozzleHolder', alignRight: false },
//   { id: 'nozzlePart', label: 'NozzlePart', alignRight: false },
//   { id: 'nozzleOpeningPressure', label: 'NozzleOpeningPressure', alignRight: false },
//   { id: 'boschNo', label: 'Bosch No', alignRight: false },
//   { id: 'stamping', label: 'Stamping', alignRight: false },
//   { id: 'euroVersion', label: 'Euro Version', alignRight: false },
//   { id: 'fisKitWithoutNozzle', label: 'FISKitWithoutNozzle', alignRight: false },
//   { id: 'fisKitWithNozzle', label: 'FISKitWithNozzle', alignRight: false },
//   { id: 'headAndRouter', label: 'HeadandRouter', alignRight: false },
//   { id: 'tpRouter', label: 'TP Router', alignRight: false },
//   { id: 'tpLinerAndBlade', label: 'TPLinerandBlade', alignRight: false },
//   { id: 'tpBladeAndSpringKit', label: 'TPBladeandSpringKit', alignRight: false },
//   { id: 'tpLiner', label: 'TPLiner', alignRight: false },
//   { id: 'solenoidSwitch', label: 'SolenoidSwitch', alignRight: false },
//   { id: 'meteringValve', label: 'MeteringValve', alignRight: false },
//   { id: 'kitBearing', label: 'KitBearing', alignRight: false },
//   { id: 'kitShaftAndBearing', label: 'KitShaftandBearing', alignRight: false },
//   { id: 'pumpHousing', label: 'PumpHousing', alignRight: false },
//   { id: 'oilSeal', label: 'OilSeal', alignRight: false },
//   { id: 'overhaulKit', label: 'OverhaulKit', alignRight: false },
//   { id: 'miniOHailKit', label: 'MiniOHailKit', alignRight: false },
//   { id: 'camRing', label: 'CamRing', alignRight: false },
//   { id: 'rollerAndShowKit', label: 'Roller and ShowKit', alignRight: false },
//   { id: 'governorArm', label: 'GovernorArm', alignRight: false },
//   { id: 'potentiometer', label: 'Potentiometer', alignRight: false },
//   { id: 'camValve', label: 'CamValve', alignRight: false },
//   { id: 'boosterAssembly', label: 'BoosterAssembly', alignRight: false },
//   { id: 'injectorAssyNLS', label: 'InjectorAssyNLS', alignRight: false },
//   { id: 'injectorAssembly', label: 'InjectorAssembly', alignRight: false },
//   { id: 'injectorBody', label: 'InjectorBody', alignRight: false },
//   { id: 'filterAssembly', label: 'FilterAssembly', alignRight: false },
//   { id: 'filterHead', label: 'FilterHead', alignRight: false },
//   { id: 'filterElement', label: 'FilterElement', alignRight: false },
//   { id: 'kitFilterElement', label: 'KitFilterElement', alignRight: false },
//   { id: 'baseFilter', label: 'BaseFilter', alignRight: false },
//   { id: 'handPrimer', label: 'HandPrimer', alignRight: false },
//   { id: 'egrSwitch', label: 'EGRSwitch', alignRight: false },
// ];

// // ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }
// const initialValues = {
//   id: '',
//   name: '',
//   description: '',
//   productType: 'default',
//   price: '',
//   imageUrl: '',
//   categoryId: 0,
//   hsnCode: '',
//   partNumber: '',
//   gst: '',
//   subCategoryId: 0,
//   brandId: 0,
//   categoryName: '',
//   firstColumnName: '',
// };
// export default function Products() {
//   const [open, setOpen] = useState(null);
//   const [openAddPopUp, setAddPopUp] = useState(false);
//   const [excelImportPopup, setExcelImportPopup] = useState(false);
//   const [isAddProductLoading, setIsAddProductLoading] = useState(false);

//   const [total, setTotal] = useState(0);
//   const [categoryList, setCategoryList] = useState([]);
//   const [page, setPage] = useState(0);

//   const [order, setOrder] = useState('asc');

//   const [selected, setSelected] = useState([]);

//   const [orderBy, setOrderBy] = useState('name');

//   const [filterName, setFilterName] = useState('');

//   const [rowsPerPage, setRowsPerPage] = useState(5);

//   const [categoryToBeUpdated, setCategoryToBeUpdated] = useState({
//     id: '',
//     name: '',
//     description: '',
//     price: '',
//     productType: 'default',
//     hsnCode: '',
//     partNumber: '',
//     gst: '',
//     imageUrl: '',
//     categoryId: 0,
//     subCategoryId: 0,
//     brandId: 0,
//     categoryName: '',
//     firstColumnName: '',
//   });

//   // const handleOpenMenu = (event, id) => {
//   //   const category = categoryList.find((brand) => brand.id === id);

//   //   category.categoryId = category.category.id;
//   //   category.brandId = category.brand.id;
//   //   category.subCategoryId = category.subCategory.id;

//   //   setCategoryToBeUpdated(category);
//   //   setOpen(event.currentTarget);
//   // };

//   const handleOpenMenu = (event, id) => {
//     const product = categoryList.find((item) => item.id === id);

//     if (product) {
//       const updatedCategory = {
//         // id: product.id,
//         // name: product.name,
//         // description: product.description,
//         // productType: product.productType,
//         // price: product.price,
//         // imageUrl: product.imageUrl,
//         // hsnCode: product.hsnCode,
//         // partNumber: product.partNumber,
//         // gst: product.gst,
//         // categoryName: product.categoryName,
//         // firstColumnName: product.firstColumnName,
//         // vehicle: product.vehicle,
//         // referenceNumber: product.referenceNumber,
//         // productName: product.productName,
//         // itemFor: product.itemFor,
//         // highPressurePump: product.highPressurePump,
//         id: product.id,
//         name: product.name,
//         description: product.description,
//         productType: product.productType,
//         price: product.price,
//         imageUrl: product.imageUrl,
//         hsnCode: product.hsnCode,
//         partNumber: product.partNumber,
//         gst: product.gst,
//         categoryName: product.categoryName,
//         firstColumnName: product.firstColumnName,
//         vehicle: product.vehicle,
//         referenceNumber: product.referenceNumber,
//         productName: product.productName,
//         itemFor: product.itemFor,
//         highPressurePump: product.highPressurePump,
//         rail: product.rail,
//         mainFilterAssy: product.mainFilterAssy,
//         mainFilterCartridge: product.mainFilterCartridge,
//         preFilterAssy: product.preFilterAssy,
//         preFilterCartridge: product.preFilterCartridge,
//         ecu: product.ecu,
//         cri: product.cri,
//         ah: product.ah,
//         dnh: product.dnh,
//         rls: product.rls,
//         mfrNumber: product.mfrNumber,
//         nozzleHolder: product.nozzleHolder,
//         nozzlePart: product.nozzlePart,
//         nozzleOpeningPressure: product.nozzleOpeningPressure,
//         boschNo: product.boschNo,
//         stamping: product.stamping,
//         euroVersion: product.euroVersion,
//         fisKitWithoutNozzle: product.fisKitWithoutNozzle,
//         fisKitWithNozzle: product.fisKitWithNozzle,
//         headAndRouter: product.headAndRouter,
//         tpRouter: product.tpRouter,
//         tpLinerAndBlade: product.tpLinerAndBlade,
//         tpBladeAndSpringKit: product.tpBladeAndSpringKit,
//         tpLiner: product.tpLiner,
//         solenoidSwitch: product.solenoidSwitch,
//         meteringValve: product.meteringValve,
//         kitBearing: product.kitBearing,
//         kitShaftAndBearing: product.kitShaftAndBearing,
//         pumpHousing: product.pumpHousing,
//         oilSeal: product.oilSeal,
//         overhaulKit: product.overhaulKit,
//         miniOHailKit: product.miniOHailKit,
//         camRing: product.camRing,
//         rollerAndShowKit: product.rollerAndShowKit,
//         governorArm: product.governorArm,
//         potentiometer: product.potentiometer,
//         camValve: product.camValve,
//         boosterAssembly: product.boosterAssembly,
//         injectorAssyNLS: product.injectorAssyNLS,
//         injectorAssembly: product.injectorAssembly,
//         injectorBody: product.injectorBody,
//         filterAssembly: product.filterAssembly,
//         filterHead: product.filterHead,
//         filterElement: product.filterElement,
//         kitFilterElement: product.kitFilterElement,
//         baseFilter: product.baseFilter,
//         handPrimer: product.handPrimer,
//         egrSwitch: product.egrSwitch,
//       };

//       if (product.category) {
//         updatedCategory.categoryId = product.category.id;
//       } else {
//         updatedCategory.categoryId = 0;
//       }

//       if (product.subCategory) {
//         updatedCategory.subCategoryId = product.subCategory.id;
//       } else {
//         updatedCategory.subCategoryId = 0;
//       }

//       if (product.brand) {
//         updatedCategory.brandId = product.brand.id;
//       } else {
//         updatedCategory.brandId = 0;
//       }

//       setCategoryToBeUpdated(updatedCategory);

//       setOpen(event.currentTarget);
//     } else {
//       console.error(`Product with ID ${id} not found in categoryList`);
//     }
//   };
//   const handleCloseMenu = () => {
//     setOpen(null);
//   };
//   const handleSubmit = async (data) => {
//     console.log("///////////////////////", data)
//     try {
//       setIsAddProductLoading(true);
//       if (data.id !== '') {
//         await updateProducts(data.id, data);
//         // const index = category.categoryList.findIndex(category => category.id === data.id)
//         // category.categoryList[index] = data;
//       } else {
//         delete data.id;
//         await createProducts(data);
//       }
//       setCategoryToBeUpdated({
//         id: '',
//         name: '',
//         description: '',
//         productType: 'default',
//         price: '',
//         hsnCode: '',
//         partNumber: '',
//         gst: '',
//         imageUrl: '',
//         categoryId: 0,
//         subCategoryId: 0,
//         brandId: 4,
//         categoryName: '',
//         firstColumnName: '',
//       });
//       await getCategoryListData();
//       setIsAddProductLoading(false);
//       setAddPopUp(false);
//       setOpen(null);
//     } catch (error) {
//       setCategoryToBeUpdated({
//         id: '',
//         name: '',
//         description: '',
//         productType: 'default',
//         price: '',
//         hsnCode: '',
//         partNumber: '',
//         gst: '',
//         imageUrl: '',
//         categoryId: 0,
//         subCategoryId: 0,
//         brandId: 0,
//         categoryName: '',
//         firstColumnName: '',
//       });
//       setIsAddProductLoading(false);

//       setAddPopUp(false);
//       setOpen(null);
//       if (error?.response.status === 401) {
//         localStorage.clear();
//         window.location.reload();
//       }
//       alert(error?.response?.data?.error || 'Something went wrong');
//     }
//   };

//   const handleRequestSort = (event, property) => {
//     const isAsc = orderBy === property && order === 'asc';
//     setOrder(isAsc ? 'desc' : 'asc');
//     setOrderBy(property);
//   };

//   const handleSelectAllClick = (event) => {
//     if (event.target.checked) {
//       const newSelecteds = USERLIST.map((n) => n.name);
//       setSelected(newSelecteds);
//       return;
//     }
//     setSelected([]);
//   };

//   const handleClick = (event, name) => {
//     const selectedIndex = selected.indexOf(name);
//     let newSelected = [];
//     if (selectedIndex === -1) {
//       newSelected = newSelected.concat(selected, name);
//     } else if (selectedIndex === 0) {
//       newSelected = newSelected.concat(selected.slice(1));
//     } else if (selectedIndex === selected.length - 1) {
//       newSelected = newSelected.concat(selected.slice(0, -1));
//     } else if (selectedIndex > 0) {
//       newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
//     }
//     setSelected(newSelected);
//   };

//   const handleChangePage = async (event, newPage) => {
//     setPage(newPage);
//   };
//   const refreshData = async () => {
//     await getCategoryListData();
//   };

//   const handleChangeRowsPerPage = async (event) => {
//     setPage(0);
//     setRowsPerPage(parseInt(event.target.value, 10));
//   };

//   const handleFilterByName = (event) => {
//     setPage(0);
//     setFilterName(event.target.value);
//   };
//   const handleDelete = async (event) => {
//     if (categoryToBeUpdated.id !== '') {
//       await deleteProducts(categoryToBeUpdated.id);
//     }
//     await getCategoryListData();
//     setOpen(null);
//   };
//   const getCategoryListData = async () => {
//     try {
//       const data = {
//         page: page + 1,
//         limit: rowsPerPage,
//         keyword: filterName,
//       };
//       const categoryRes = await getProductsList(data);
//       const { productList, count } = categoryRes.data;
//       setTotal(count);
//       console.log('Fetched productList:', productList); // Log fetched productList
//       console.log('Total count:', count); // Log total count
//       setCategoryList(productList);
//     } catch (error) {
//       if (error?.response.status === 401) {
//         localStorage.clear();
//         window.location.reload();
//       }
//       alert(error?.response?.data?.error || 'Something went wrong');
//     }
//   };

//   useEffect(() => {
//     getCategoryListData();
//   }, [page, rowsPerPage, filterName]);

//   const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

//   // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

//   const isNotFound = !categoryList.length && !!filterName;

//   return (
//     <>
//       <Helmet>
//         <title> Products </title>
//       </Helmet>

//       <Container>
//         <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
//           <Typography variant="h4" gutterBottom>
//             Products
//           </Typography>
//           <Button
//             variant="contained"
//             onClick={() => {
//               setCategoryToBeUpdated(initialValues);
//               setAddPopUp(true);
//             }}
//             startIcon={<Iconify icon="eva:plus-fill" />}
//           >
//             Add Product
//           </Button>
//         </Stack>

//         <Card>
//           <CategoryListToolBar
//             numSelected={selected.length}
//             filterName={filterName}
//             onFilterName={handleFilterByName}
//             setExcelImportPopupFlag={() => setExcelImportPopup(true)}
//           />

//           <Scrollbar>
//             <TableContainer sx={{ minWidth: 800 }}>
//               <Table>
//                 <CategoryListHead
//                   order={order}
//                   orderBy={orderBy}
//                   headLabel={TABLE_HEAD}
//                   rowCount={total}
//                   numSelected={selected.length}
//                   onRequestSort={handleRequestSort}
//                   onSelectAllClick={handleSelectAllClick}
//                 />
//                 <TableBody>
//                   {categoryList.slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage).map((row) => {
//                     const {
//                       id,
//                       name,
//                       SNo,
//                       description,
//                       price,
//                       imageUrl,
//                       gst,
//                       hsnCode,
//                       partNumber,
//                       category,
//                       Category,
//                       brand,
//                       productType,
//                       subCategory,
//                       categoryName,
//                       firstColumnName,
//                       vehicle,
//                       itemType,
//                       itemFor,
//                       productName,
//                       referenceNumber,
//                       suitableFor,
//                       subCategoryId,
//                       highPressurePump,
//                       rail,
//                       mainFilterAssy,
//                       mainFilterCartridge,
//                       preFilterAssy,
//                       preFilterCartridge,
//                       ecu,
//                       cri,
//                       ah,
//                       dnh,
//                       rls,
//                       mfrNumber,
//                       nozzleHolder,
//                       nozzlePart,
//                       nozzleOpeningPressure,
//                       boschNo,
//                       stamping,
//                       euroVersion,
//                       fisKitWithoutNozzle,
//                       fisKitWithNozzle,
//                       headAndRouter,
//                       tpRouter,
//                       tpLinerAndBlade,
//                       tpBladeAndSpringKit,
//                       tpLiner,
//                       solenoidSwitch,
//                       meteringValve,
//                       kitBearing,
//                       kitShaftAndBearing,
//                       repairKitPartNumber,
//                       pumpHousing,
//                       oilSeal,
//                       overhaulKit,
//                       miniOHailKit,
//                       camRing,
//                       rollerAndShowKit,
//                       governorArm,
//                       potentiometer,
//                       camValve,
//                       boosterAssembly,
//                       injectorAssyNLS,
//                       injectorAssembly,
//                       injectorBody,
//                       filterAssembly,
//                       filterHead,
//                       filterElement,
//                       kitFilterElement,
//                       baseFilter,
//                       handPrimer,
//                       egrSwitch,
//                     } = row;
//                     const selectedUser = selected.indexOf(name) !== -1;
//                     return (
//                         <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {id}
//                             </Typography>
//                           </TableCell>
//                           {/* <TableCell align="left">
//       <Typography variant="subtitle2" noWrap>
//         {SNo}
//       </Typography>
//     </TableCell> */}
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {partNumber}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {vehicle}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {repairKitPartNumber}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Stack direction="row" alignItems="center" spacing={2}>
//                               {/* <Avatar alt={name} src={imageUrl} /> */}

//                               <a href={imageUrl} target="_blank" rel="noreferrer">
//                                 {' '}
//                                 File{' '}
//                               </a>
//                             </Stack>
//                           </TableCell>

//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {productType}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {referenceNumber}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {productName}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {itemFor}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {Category}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {categoryName}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {suitableFor}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             {category?.name && category?.id ? `${category.name} (${category.id})` : category?.name || ""}
//                           </TableCell>
//                           <TableCell align="left">
//                             {subCategory?.name && subCategory?.id ? `${subCategory.name} (${subCategory.id})` : subCategory?.name || ""}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {highPressurePump}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {rail}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {mainFilterAssy}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {mainFilterCartridge}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {preFilterAssy}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {preFilterCartridge}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {ecu}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {cri}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {ah}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {dnh}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {rls}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {mfrNumber}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {nozzleHolder}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {nozzlePart}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {nozzleOpeningPressure}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {boschNo}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {stamping}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {euroVersion}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {fisKitWithoutNozzle}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {fisKitWithNozzle}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {headAndRouter}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {tpRouter}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {tpLinerAndBlade}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {tpBladeAndSpringKit}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {tpLiner}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {solenoidSwitch}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {meteringValve}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {kitBearing}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {kitShaftAndBearing}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {pumpHousing}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {oilSeal}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {overhaulKit}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {miniOHailKit}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {camRing}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {rollerAndShowKit}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {governorArm}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {potentiometer}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {camValve}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {boosterAssembly}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {injectorAssyNLS}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {injectorAssembly}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {injectorBody}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {filterAssembly}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {filterElement}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {/* {waterSeparator} */}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {/* {sealKit} */}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {/* {spacerKit} */}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {/* {other} */}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <Typography variant="subtitle2" noWrap>
//                               {/* {other} */}
//                             </Typography>
//                           </TableCell>
//                           <TableCell align="left">
//                             <IconButton
//                               size="large"
//                               color="inherit"
//                               onClick={(event) => {
//                                 handleOpenMenu(event, id);
//                               }}
//                             >
//                               <Iconify icon={'eva:more-vertical-fill'} />
//                             </IconButton>
//                           </TableCell>
//                         </TableRow>



//                     );
//                   })}
//                   {/* {emptyRows > 0 && (
//                     <TableRow style={{ height: 53 * emptyRows }}>
//                       <TableCell colSpan={6} />
//                     </TableRow>
//                   )} */}
//                 </TableBody>

//                 {isNotFound && (
//                   <TableBody>
//                     <TableRow>
//                       <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
//                         <Paper
//                           sx={{
//                             textAlign: 'center',
//                           }}
//                         >
//                           <Typography variant="h6" paragraph>
//                             Not found
//                           </Typography>

//                           <Typography variant="body2">
//                             No results found for &nbsp;
//                             <strong>&quot;{filterName}&quot;</strong>.
//                             <br /> Try checking for typos or using complete words.
//                           </Typography>
//                         </Paper>
//                       </TableCell>
//                     </TableRow>
//                   </TableBody>
//                 )}
//               </Table>
//             </TableContainer>
//           </Scrollbar>

//           <TablePagination
//             rowsPerPageOptions={[5, 10, 20, 30, 50]}
//             component="div"
//             count={total}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onPageChange={handleChangePage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </Card>
//       </Container>

//       <AddProduct
//         open={openAddPopUp}
//         onPopUpClose={() => setAddPopUp(false)}
//         loading={isAddProductLoading}
//         onSubmit={handleSubmit}
//         initialValues={categoryToBeUpdated}
//       />
//       <ExcelImport open={excelImportPopup} onPopUpClose={() => setExcelImportPopup(false)} refreshData={refreshData} />
//       <Popover
//         open={Boolean(open)}
//         anchorEl={open}
//         onClose={handleCloseMenu}
//         anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
//         transformOrigin={{ vertical: 'top', horizontal: 'right' }}
//         PaperProps={{
//           sx: {
//             p: 1,
//             width: 140,
//             '& .MuiMenuItem-root': {
//               px: 1,
//               typography: 'body2',
//               borderRadius: 0.75,
//             },
//           },
//         }}
//       >
//         <MenuItem onClick={() => setAddPopUp(true)}>
//           <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
//           Edit
//         </MenuItem>

//         <MenuItem sx={{ color: 'error.main' }} onClick={handleDelete}>
//           <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
//           Delete
//         </MenuItem>
//       </Popover>
//       <Container sx={{ mt: 10 }}>
//         <Footer />
//       </Container>
//     </>
//   );
// }


import { Helmet } from 'react-helmet-async';
import { filter, initial } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { API_URL } from '../utils/constant';
import Footer from './Footer';

import { AddProduct, CategoryListHead, CategoryListToolBar, ExcelImport } from '../sections/@dashboard/products';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections

// mock
import USERLIST from '../_mock/user';
import { getProductsList, createProducts, updateProducts, deleteProducts } from '../api/products';
  
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Product ID', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'description', label: 'Description', alignRight: false },
  // { id: 'price', label: 'price', alignRight: false },
  { id: 'imageurl', label: 'Image', alignRight: false },
  { id: 'productType', label: 'type', alignRight: false },
  // { id: 'hsn', label: 'HSN', alignRight: false },
  { id: 'partNumber', label: 'Part Number', alignRight: false },
  { id: 'vehicle', label: 'vehicle', alignRight: false },
  { id: 'application', label: 'Application', alignRight: false },
  { id: 'firstColumnName', label: 'firstColumnName', alignRight: false },
  { id: 'categoryName', label: 'categoryName', alignRight: false },
  { id: 'highPressurePump', label: 'highPressurePump', alignRight: false },
  { id: 'rail', label: 'rail', alignRight: false },
  { id: 'mainFilterAssy', label: 'mainFilterAssy', alignRight: false },
  { id: 'mainFilterCartridge', label: 'mainFilterCartridge', alignRight: false },
  { id: 'preFilterAssy', label: 'preFilterAssy', alignRight: false },
  { id: 'preFilterCartridge', label: 'preFilterCartridge', alignRight: false },
  { id: 'ecu', label: 'ecu', alignRight: false },
  // { id: 'gst', label: 'GST(in %)', alignRight: false },
  // { id: 'brand', label: 'Brand', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'sub-category', label: 'Sub Category', alignRight: false },

  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const initialValues = {
  id: '',
  name: '',
  description: '',
  productType: 'default',
  price: '',
  imageUrl: '',
  categoryId: 0,
  hsnCode: '',
  partNumber: '',
  gst: '',
  subCategoryId: 0,
  brandId: 0,
};


// const initialValues = {
//   name: '',
//   description: '',
//   imageUrl: '',
//   price: 0,
//   hsnCode: '',
//   partNumber: '',
//   gst: '',
//   productType: 'default',
//   brandId: 0,
//   categoryId: 0,
//   subCategoryId: 0,
//   vehicle: '',
//   repairKitPartNumber: '',
//   nozzle: '',
//   valveSet: '',
//   partSet: '',
//   application: '',
//   firstColumnName: '',
//   categoryName: '',
//   highPressurePump: '',
//   rail: '',
//   mainFilterAssy: '',
//   mainFilterCartridge: '',
//   preFilterAssy: '',
//   preFilterCartridge: '',
//   ecu: '',
//   referenceNumber: '',
//   productName: '',
//   itemFor: '',
//   category: '',
//   suitableFor: '',
//   cri: '',
//   ah: '',
//   dnh: '',
//   rls: '',
//   mfrNumber: '',
//   nozzleHolder: '',
//   nozzlePart: '',
//   nozzleOpeningPressure: '',
//   boschNo: '',
//   stamping: '',
//   euroVersion: '',
//   fisKitWithoutNozzle: '',
//   fisKitWithNozzle: '',
//   headAndRouter: '',
//   tpRouter: '',
//   tpLinerAndBlade: '',
//   tpBladeAndSpringKit: '',
//   tpLiner: '',
//   solenoidSwitch: '',
//   meteringValve: '',
//   kitBearing: '',
//   kitShaftAndBearing: '',
//   pumpHousing: '',
//   oilSeal: '',
//   overhaulKit: '',
//   miniOHailKit: '',
//   camRing: '',
//   rollerAndShowKit: '',
//   governorArm: '',
//   potentiometer: '',
//   camValve: '',
//   boosterAssembly: '',
//   injectorAssyNLS: '',
//   injectorAssembly: '',
//   injectorBody: '',
//   filterAssembly: '',
//   filterHead: '',
//   filterElement: '',
//   kitFilterElement: '',
//   baseFilter: '',
//   handPrimer: '',
//   egrSwitch: '',
// };

export default function Products() {
  const [open, setOpen] = useState(null);
  const [openAddPopUp, setAddPopUp] = useState(false);
  const [excelImportPopup, setExcelImportPopup] = useState(false);
  const [isAddProductLoading, setIsAddProductLoading] = useState(false);

  const [total, setTotal] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [categoryToBeUpdated, setCategoryToBeUpdated] = useState({
    // id: '',
    name: '',
    description: '',
    price: '',
    productType: 'default',
    hsnCode: '',
    partNumber: '',
    gst: '',
    imageUrl: '',
    categoryId: 0,
    subCategoryId: 0,
    brandId: 0,
    // repairKitPartNumber: '',
    // nozzle: '',
    // valveSet: '',
    // partSet: '',
    // application: '',
    // firstColumnName: '',
    // categoryName: '',
    // highPressurePump: '',
    // rail: '',
    // mainFilterAssy: '',
    // mainFilterCartridge: '',
    // preFilterAssy: '',
    // preFilterCartridge: '',
    // ecu: '',
    // referenceNumber: '',
    // productName: '',
    // itemFor: '',
    // category: '',
    // suitableFor: '',
    // cri: '',
    // ah: '',
    // dnh: '',
    // rls: '',
    // mfrNumber: '',
    // nozzleHolder: '',
    // nozzlePart: '',
    // nozzleOpeningPressure: '',
    // boschNo: '',
    // stamping: '',
    // euroVersion: '',
    // fisKitWithoutNozzle: '',
    // fisKitWithNozzle: '',
    // headAndRouter: '',
    // tpRouter: '',
    // tpLinerAndBlade: '',
    // tpBladeAndSpringKit: '',
    // tpLiner: '',
    // solenoidSwitch: '',
    // meteringValve: '',
    // kitBearing: '',
    // kitShaftAndBearing: '',
    // pumpHousing: '',
    // oilSeal: '',
    // overhaulKit: '',
    // miniOHailKit: '',
    // camRing: '',
    // rollerAndShowKit: '',
    // governorArm: '',
    // potentiometer: '',
    // camValve: '',
    // boosterAssembly: '',
    // injectorAssyNLS: '',
    // injectorAssembly: '',
    // injectorBody: '',
    // filterAssembly: '',
    // filterHead: '',
    // filterElement: '',
    // kitFilterElement: '',
    // baseFilter: '',
    // handPrimer: '',
    // egrSwitch: '',
  });

  // const handleOpenMenu = (event, id) => {
  //   const category = categoryList.find((brand) => brand.id === id);

  //   category.categoryId = category.category.id;
  //   // category.brandId = category.brand.id;
  //   category.subCategoryId = category.subCategory.id;

  //   setCategoryToBeUpdated(category);
  //   setOpen(event.currentTarget);
  // };

  const handleOpenMenu = (event, id) => {
    const product = categoryList.find((item) => item.id === id);

    if (product) {
      const updatedCategory = {
        id: product.id,
        name: product.name,
        description: product.description,
        productType: product.productType,
        price: product.price,
        imageUrl: product.imageUrl,
        hsnCode: product.hsnCode,
        partNumber: product.partNumber,
        gst: product.gst,
        categoryName: product.categoryName,
      };

      if (product.category) {
        updatedCategory.categoryId = product.category.id;
      } else {
        updatedCategory.categoryId = 0;
      }

      if (product.subCategory) {
        updatedCategory.subCategoryId = product.subCategory.id;
      } else {
        updatedCategory.subCategoryId = 0;
      }

      if (product.brand) {
        updatedCategory.brandId = product.brand.id;
      } else {
        updatedCategory.brandId = 0;
      }

      setCategoryToBeUpdated(updatedCategory);

      setOpen(event.currentTarget);
    } else {
      console.error(`Product with ID ${id} not found in categoryList`);
    }
  };


  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleSubmit = async (data) => {
    try {
      setIsAddProductLoading(true);
      if (data.id !== '') {
        await updateProducts(data.id, data);
        // const index = category.categoryList.findIndex(category => category.id === data.id)
        // category.categoryList[index] = data;
      } else {
        delete data.id;
        await createProducts(data);
      }
      setCategoryToBeUpdated({
        id: '',
        name: '',
        description: '',
        productType: 'default',
        price: '',
        hsnCode: '',
        partNumber: '',
        gst: '',
        imageUrl: '',
        categoryId: 0,
        subCategoryId: 0,
        brandId: 4,
        categoryName: '',
      });
      await getCategoryListData();
      setIsAddProductLoading(false);
      setAddPopUp(false);
      setOpen(null);
    } catch (error) {
      setCategoryToBeUpdated({
        id: '',
        name: '',
        description: '',
        productType: 'default',
        price: '',
        hsnCode: '',
        partNumber: '',
        gst: '',
        imageUrl: '',
        categoryId: 0,
        subCategoryId: 0,
        brandId: 0,
        categoryName: '',
      });
      setIsAddProductLoading(false);

      setAddPopUp(false);
      setOpen(null);
      if (error?.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };
  const refreshData = async () => {
    await getCategoryListData();
  };

  const handleChangeRowsPerPage = async (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const handleDelete = async (event) => {
    if (categoryToBeUpdated.id !== '') {
      await deleteProducts(categoryToBeUpdated.id);
    }
    await getCategoryListData();
    setOpen(null);
  };
  const getCategoryListData = async () => {
    try {
      const data = {
        page: page + 1,
        limit: rowsPerPage,
        keyword: filterName,
      };

      const categoryRes = await getProductsList(data);
      const { productList, count } = categoryRes.data;
      setTotal(count);

      console.log('Fetched productList:', productList); // Log fetched productList
      console.log('Total count:', count); // Log total count
      setCategoryList(productList);
    } catch (error) {
      if (error?.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }
  };

  useEffect(() => {
    getCategoryListData();
  }, [page, rowsPerPage, filterName]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !categoryList.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Products </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Products
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setCategoryToBeUpdated(initialValues);
              setAddPopUp(true);
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Product
          </Button>
        </Stack>

        <Card>
          <CategoryListToolBar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            setExcelImportPopupFlag={() => setExcelImportPopup(true)}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <CategoryListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={total}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {/* {categoryList.slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage).map((row) => { */}
                  {/* removed slice for now because backend is already doing it for us */}
                  {categoryList.map((row) => {
                    const {
                      id,
                      name,
                      description,
                      price,
                      imageUrl,
                      gst,
                      hsnCode,
                      partNumber,
                      category,
                      brand,
                      productType,
                      subCategory,
                      categoryName,
                      nozzle,
                      vehicle,
                      application,
                      firstColumnName,
                      highPressurePump,
                      rail,
                      mainFilterAssy,
                      mainFilterCartridge,
                      preFilterAssy,
                      preFilterCartridge,
                      ecu,
                    } = row;
                    const selectedUser = selected.indexOf(name) !== -1;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        {/* <TableCell padding="checkbox">
                                                    <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                                                </TableCell> */}

                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {id}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {name}
                            {/* {nozzle} */}
                          </Typography>
                        </TableCell>

                        {/* <TableCell align="left">{description}</TableCell> */}
                        {/* <TableCell align="left">{price}</TableCell> */}

                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} src={imageUrl} /> */}

                            <a href={imageUrl} target="_blank" rel="noreferrer">
                              {' '}
                              File{' '}
                            </a>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{productType}</TableCell>
                        <TableCell align="left">{partNumber}</TableCell>
                        <TableCell align="left">{vehicle}</TableCell>
                        <TableCell align="left">{application}</TableCell>
                        <TableCell align="left">{firstColumnName}</TableCell>
                        <TableCell align="left">{categoryName}</TableCell>
                        <TableCell align="left">{highPressurePump}</TableCell>
                        <TableCell align="left">{mainFilterAssy}</TableCell>
                        <TableCell align="left">{mainFilterCartridge}</TableCell>
                        <TableCell align="left">{rail}</TableCell>
                        <TableCell align="left">{preFilterAssy}</TableCell>
                        <TableCell align="left">{preFilterCartridge}</TableCell>
                        <TableCell align="left">{ecu}</TableCell>
                        {/* <TableCell align="left">{hsnCode}</TableCell>
                        <TableCell align="left">{gst}</TableCell> */}
                        {/* <TableCell align="left">
                          {brand.name} ({brand.id})
                        </TableCell> */}
                        <TableCell align="left">
                          {category?.name && category?.id ? `${category.name} (${category.id})` : category?.name || ""}
                          {/* {categoryName} */}
                        </TableCell>
                        <TableCell align="left">
                          {subCategory?.name && subCategory?.id ? `(${subCategory.name}) (${subCategory.id})` : subCategory?.name || ""}
                        </TableCell>


                        <TableCell align="left">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => {
                              handleOpenMenu(event, id);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 30, 50]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <AddProduct
        open={openAddPopUp}
        onPopUpClose={() => setAddPopUp(false)}
        loading={isAddProductLoading}
        onSubmit={handleSubmit}
        initialValues={categoryToBeUpdated}
      />
      <ExcelImport open={excelImportPopup} onPopUpClose={() => setExcelImportPopup(false)} refreshData={refreshData} />
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setAddPopUp(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={handleDelete}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <Container sx={{ mt: 10 }}>
        <Footer />
      </Container>
    </>
  );
}

