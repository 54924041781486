import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import axios from 'axios';

import { toast } from 'react-toastify';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
} from '@mui/material';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import Footer from './Footer';
// sections

import { getBrandList } from '../api/brand';
import { AddPlan, CategoryListHead, CategoryListToolBar } from '../sections/@dashboard/plan';
import AddNotification from '../sections/@dashboard/notification/AddNotification';


// mock
import USERLIST from '../_mock/user';
import { category } from '../_mock/category';
import { getPlanList, updatePlan, createPlan, deletePlan } from '../api/plan';
// import { API_URL } from '../../utils/constant';
import { API_URL } from '../utils/constant';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'id', label: 'Plan ID', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'expiresIn', label: 'Validity (days)', alignRight: false },
    { id: 'price', label: 'Price', alignRight: false },

    { id: '' },
];

// ----------------------------------------------------------------------
const refreshToken = async (refreshToken, clientId, clientSecret, tokenEndpoint) => {
    const body = new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: clientId,
        client_secret: clientSecret,
    });

    try {
        const response = await fetch(tokenEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: body.toString(),
        });

        if (!response.ok) {
            throw new Error(`Failed to refresh token: ${response.statusText}`);
        }

        const data = await response.json();
        return data; // This will contain the new access token and possibly a new refresh token.
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
    }
};
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}
const initialValues = {
    id: '',
    name: '',
    expiresIn: '',
};

export default function SendNotification() {
    const [open, setOpen] = useState(null);
    const [openAddPopUp, setAddPopUp] = useState(false);
    const [isAddProductLoading, setIsAddProductLoading] = useState(false);

    const [total, setTotal] = useState(0);
    const [categoryList, setCategoryList] = useState([]);
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [categoryToBeUpdated, setCategoryToBeUpdated] = useState({
        id: '',
        name: '',
        expiresIn: '',
        price: '',
    });


    const [fmctoken, setFmctoken] = useState([]);



    const handleOpenMenu = (event, id) => {
        const category = categoryList.find((brand) => brand.id === id);

        setCategoryToBeUpdated(category);
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    // --------------------------fetch all fcm token----------------------
    const limit = 9999999;
    const perpage = 1;
    const keyword = '';



    
const fetchFcmToken = async () => {
    try {
        const response = await axios.get(`${API_URL}/users/list?limit=${limit}&page=${perpage}&keyword=${keyword}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        console.log("token data", response.data);

        const { userList } = response.data.data;
        const fcmTokens = userList.map(user => user.fcmToken).filter(token => token); 
        console.log(fcmTokens)
        setFmctoken(fcmTokens);
    } catch (error) {
        console.error('Error fetching FCM tokens:', error);
        toast.error('Failed to fetch FCM tokens');
    }
}








    // const handleSubmit = async (data) => {

    //     const url = 'https://fcm.googleapis.com/fcm/send';
    //     const authToken = 'AAAAFHnez5o:APA91bFhRR78Wb0sq7fcBhajI6OyOd_lXC6Z04sFqwQH8njSiPcZX34F3mLAcJs9FnfxzAyUOnqkbkoL-s6g1SEPLzhSPR17TyDt8dnHsaL6Z5KHGh_xVfjlp23g6HNsh_dop5vVT6dn';

    //     try {
    //         const response = await axios.post(url, {

    //             registration_ids: fmctoken,
    //             notification: {
    //                 title: data.title,
    //                 body: data.subtitle
    //             }
    //         }, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `key=${authToken}`
    //             }
    //         });

    //         console.log(response.data);
    //         alert('Notification sent successfully!');
    //         setAddPopUp(false);
    //         console.log(data, "submitted data");
    //     } catch (error) {
    //         console.error(error);
    //         alert(error?.response?.data?.error || 'Something went wrong!');

    //     }

    // }



// const handleSubmit = async (data) => {
//     const url = 'https://fcm.googleapis.com/v1/projects/dieselduniya/messages:send'; 
//     const authToken = 'ya29.c.c0ASRK0Gblm4e5Bn21dLxbrVekkmH9AVOdauV6Q_69f7yTwjQiHphIaZPmcGZkRy_EoVkKWEMHvwWzdQgQpKFipwvVzaN71wG64cSWDzXuKOKVexwnXie_jP9FgmeCfwpNcEnPXsLjLgwuDPlHDxHeqxOTh8fig-jDDg99Hr3N33uDzOqVNQT6k7Myo-eBB4UGy75OFVtGMqR_7L7jJKks6vmqFAWoc0qdlc3kpqiziHIkEkhajrZRGCSGPET7Kh8gSO54v9IadKGqNzB1TROsdI0p1iuVpAbv1A0Quf3dOE1tb9Jyc-8Ny2MnMrAPZUErd9z5Z1p6iQgWtqFkJfIzd5rKdCDNIYbOuA9qPrsKsMWJWUXNCzxB3472T385Ad7UqpIBtFf2WmxyOziq1gm4ukIqQ6WY_fUhqeM0tMy4wbIWa3Vgq7IXoJj5a_5Unl3qW0Yocqer-SOM3OuZmV8Sn09S-w4fqzMM3M22X5foIpU067zt8J_e8t4ksXselS1gzsrSiW9aaJfr_kZ22reemI6idg1S1wjv-vu4ky3gYw3kt17FM156Xm4vq9cgQxkIZ7msYjmXb8y9SBMSM2X7y820vBW7_s2FVnyrup1_-Qolv2c0eylWamcwurWnMWXmd4hYS3fgfm-kO4fU6zxjrV_zcOR_6yaqafOXfpVcF2aggSnQSty9e4epcFdZUrxS45IdgrIrxn3uzUbstyXOvy0n_8X9kJvJSb61MUru4bqte6h30zo0WbSF1c9SMMs7RvRXrZperlaq7bOBz4VeF3xfui7SfBxpZrW-ziyssQB-1Qz1XaQzYMWR4wgU33bBnsW03Smm8W-t6whheBrspx6ds88MjZJsufZcayMSZW5bY0c5__jpo2dzp1dyQzjjOyXv6-h4jbyIh97vZilmUVs-axnVpVIqigXiR1IumlQ8_mW8emBhp412ZbUMfeF6S4_-WnX8J7lw-VUFzhuo2udUqjgyrvuRYRus3S6q7e1efwh-rpYWo2F'; 

//     // const fmctoken = 'APA91bEu_gXJ5VWAE97eikb0g5M_Qtvv2TsJxWG8rT4BgT6OS9FJMV8q2SkFELWn0SfWvMabKsjqeruXPVKG844mpQXjISU3sGXodt-1uqjpPgnHIeV-utdPZCLtRWhX8ag4_MjVp5iX'; 

//     const payload = {
//         message: {
//             tokens : fmctoken,
//             notification: {
//                 title: data.title,
//                 body: data.subtitle,
//             },
//             data: {} 
//         }
//     };

//     console.log('Sending notification request...');
//     console.log('URL:', url);
//     console.log('Headers:', {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${authToken}`
//     });
//     console.log('Payload:', payload);

//     try {
//         const response = await axios.post(url, payload, {
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${authToken}`
//             }
//         });
//         console.log('Response received:');
//         console.log('Status:', response.status);
//         console.log('Data:', response.data);

//         alert('Notification sent successfully!');
//         setAddPopUp(false);
//     } catch (error) {
//         console.error('Error sending notification:', error);

//         if (error.response) {
//             console.error('Response error data:', error.response.data);
//             console.error('Response error status:', error.response.status);
//         } else {
//             console.error('Error message:', error.message);
//         }

//         alert(error?.response?.data?.error || 'Something went wrong!');
//     }
// };

  const [accessToken, setAccessToken] = useState('');

 async function fetchToken() {
  try {
    const response = await axios.post(`${API_URL}/token/generate-token`);
    console.log('Token fetched successfully:', response.data.token);
    setAccessToken(response.data.token); 
  } catch (error) {
    console.error('Error fetching access token:', error.message);
    throw error;
  }
}

  useEffect(() => {
    fetchToken();
    const interval = setInterval(fetchToken, 60 * 60 * 1000);
    return () => clearInterval(interval); 
  }, []);

// const handleSubmit = async (data) => {
//     const url = 'https://fcm.googleapis.com/v1/projects/dieselduniya/messages:send';
//     // const authToken = "ya29.c.c0ASRK0GZFw6BjdYjplGo2bxiMO-nkcJhTcSMPn44GvNJrrxtvc6vvUDK6wWYDm2A0zOOMHkFLhysxJYOzwTv7Dt2fDoCs5ZpYAM_LxjPSjVWCGkal_EThXqlm0XE_7Qb0ovkk_QmX7vzK3RonoyLoMMYIWygXxRt3Dwh4psXfr08sqVIR78Rsfmfgy8UE8SOHaY9jcAYYK3_ibpNw6VCi9nnLI8hOo8LkQQc4hQ0pqlCLEBgQ84jFczSyRJwQ2tL0YlVceMwc2LX8a4hny-OOHlIgXc6jQ8OXABvCR5PCKT4xY3EN9o0ecvAiRHSDmdCXF655Wpvdonb-vI74sbX2uPxYhX2S2NR4VXHvihA6LnZUkKyIqdc2iHgFN385Ax_kRwJbs0h1V1h1Zo_sgRnZS2RhBczJbUwMJUrtF_FyoRtSlsY43urqqO0o93OYipbrQiRa6UIh8gkcO7Rp-Fyx-Qf2ugO2OXQVqOaIBIXoyI39_9z0d2q-0uR8l45I53cOYapsw4c0x68Z04VjFcMX2y0g9t1jFIy3O9kkIJss7h0oU-BzZyZkc19QIp2xk-z9szXOZwQi-iypdoflfwSOQIrqJkQvpm1aMall2tfy89ajw_rSOiWkcBFra3i-UrVUevxxMrnig9jVYRv6u__pxss3MUF6Iz2Qq4w1pZZjBwt_j97OyRxi2eoRBrcV_8qgqZdnOkBJIn284u_7UJ2eXffl9dq2epWU56VOs0kqJd1q0SVuWUViWZBOQZtnMMkWVas7VsYqJgZr94v9456Zz4Vha__rkVgR8BkaXtfbdsB_szJqwUvRj5y0t07XWu54g8dbSayh79Fr9X7OogBMg-Ok_tqorQlFpv6F_Z3l7vM_vQaRS5ftyRlWuShjdZvd4z9s5oj87nqekQ3nU9j4J0w9a6m5ZFpS8wc9t4FaFeyuZ8IbgXmd7y3oh0IZ5YeeBsYRvn3kWn515ke9OXh_umWz58-5FvXzQjW2WnUe1OMMjXfy56Ouuck";
//     const authToken= accessToken;

//     const payload = {
//         message: {
//         topic: "high_importance_channel",
//         // token: 'cSTVYC0XQEGOCS-zcD4CPW:APA91bHdhjkzbgW7kv7mazCv5n6gsK1DUvHwm5VcwnMGnFECFiYUOBtV3UBx_QvS3VPwFt9x2_BT_HsYeOwDRCNKG90upiWSwcRvYKnOYWgp8XSZlAlo6ALD_367_9kxXXJCxygPVlZb',
//         //    kajal mam
//         //   token: 'cSTVYC0XQEGOCS-zcD4CPW:APA91bHdhjkzbgW7kv7mazCv5n6gsK1DUvHwm5VcwnMGnFECFiYUOBtV3UBx_QvS3VPwFt9x2_BT_HsYeOwDRCNKG90upiWSwcRvYKnOYWgp8XSZlAlo6ALD_367_9kxXXJCxygPVlZb', 
//         //   akash sir
//             notification: {
//                 title: data.title,
//                 body: data.subtitle,
//             },
//         }
//     };

//     console.log('Sending notification request...');
//     console.log('URL:', url);
//     console.log('Headers:', {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${authToken}`
//     });
//     console.log('Payload:', payload);

//     try {
//         const response = await axios.post(url, payload, {
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${authToken}`
//             }
//         });
//         console.log('Response received:');
//         console.log('Status:', response.status);
//         console.log('Data:', response.data);

//         alert('Notification sent successfully!');
//         setAddPopUp(false);
//     } catch (error) {
//         console.error('Error sending notification:', error);

//         if (error.response) {
//             console.error('Response error data:', error.response.data);
//             console.error('Response error status:', error.response.status);
//             alert(error.response.data.error.message || 'Something went wrong!');
//         } else {
//             console.error('Error message:', error.message);
//             alert(error.message || 'Something went wrong!');
//         }
//     }
// };




const handleSubmit = async (data) => {
    const url = 'https://fcm.googleapis.com/v1/projects/diesel-duniya-cbfd9/messages:send';
    const authToken = accessToken;
    console.log(authToken)
    const payload = {
        message: {
            topic: 'news', 
            notification: {
                title: data.title,
                body: data.subtitle,
            },
            data: {}
        }
    };

    try {
        const response = await axios.post(url, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        });
        console.log('Notification sent successfully:', response.data);
        toast.success('Notification sent successfully!');
    } catch (error) {
        console.error('Error sending notification:', error);
        toast.error(error?.response?.data?.error?.message || 'Something went wrong!');
    }
};

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = async (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };
    const handleDelete = async (event) => {
        try {
            if (categoryToBeUpdated.id !== '') {
                await deletePlan(categoryToBeUpdated.id);
            }
            await getCategoryListData();
            setOpen(null);
        } catch (error) {
            if (error?.response.status === 401) {
                localStorage.clear();
                window.location.reload();
            }
            toast.error(error?.response?.data?.error || 'Something went wrong');
        }
    };
    const getCategoryListData = async () => {
        try {
            const data = {
                page: page + 1,
                limit: rowsPerPage,
                keyword: filterName,
            };
            const categoryRes = await getPlanList(data);
            const { planList, count } = categoryRes.data;
            setTotal(count);
            setCategoryList(planList);
        } catch (error) {
            if (error?.response.status === 401) {
                localStorage.clear();
                window.location.reload();
            }
            toast.error(error?.response?.data?.error || 'Something went wrong');
        }
    };
    useEffect(() => {
        getCategoryListData();
        fetchFcmToken()
    }, [page, rowsPerPage, filterName]);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

    // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

    const isNotFound = !categoryList.length && !!filterName;

    return (
        <>
            <Helmet>
                <title>Notification</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Notification
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setCategoryToBeUpdated(initialValues);
                            setAddPopUp(true);
                        }}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                        Send Notification
                    </Button>
                </Stack>


            </Container>

            {/* <AddPlan
                open={openAddPopUp}
                onPopUpClose={() => {
                    setCategoryToBeUpdated(initialValues);
                    setAddPopUp(false);
                }}
                loading={isAddProductLoading}
                onSubmit={handleSubmit}
                initialValues={categoryToBeUpdated}
            /> */}
            <AddNotification
                open={openAddPopUp}
                onPopUpClose={() => {

                    setAddPopUp(false);
                }}
                loading={isAddProductLoading}
                onSubmit={handleSubmit}

            />
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={() => setAddPopUp(true)}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }} onClick={handleDelete}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover>
            <Container sx={{ mt: 10 }}>
                <Footer />
            </Container>
        </>
    );
}
