import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
// @mui

import {
  Link,
  Stack,
  Select,
  FormHelperText,
  MenuItem,
  Grid,
  CircularProgress,
  InputLabel,
  IconButton,
  InputAdornment,
  TextField,
  Input,
  Checkbox,
  Box,
  Card,
  Typography,
  Modal,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// utils
import { LoadingButton } from '@mui/lab';
import { API_URL } from '../../../utils/constant';

import { uploadExcelForFip } from '../../../api/upload';
import { getCategoryList } from '../../../api/category';
import { getSubCatgoryList } from '../../../api/sub-category';
import { getBrandList } from '../../../api/brand';

// components

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  maxHeight: '100%',
};

// ----------------------------------------------------------------------

// ShopProductCard.propTypes = {
//     product: PropTypes.object,
// };

export default function ExcelImport({ open, onPopUpClose, refreshData }) {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formError, setFormError] = useState(false);

  const handleClick = async (event) => {
    try {
      setLoading(true);

      event.preventDefault();
      setFormError(false);
      if (!selectedFile) {
        setFormError(true);

        setLoading(false);
        return false;
      }
      if (!selectedFile.name.endsWith('.xlsx')) {
        toast.error('Please upload excel file');
        setLoading(false);

        return true;
      }

      await uploadExcelForFip(selectedFile);

      setSelectedFile(null);

      setLoading(false);
      handleClose();
      refreshData();
      return true;
    } catch (error) {
      if (error?.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      toast.error(error?.response?.data?.error || 'Something went wrong');

      setLoading(false);
      handleClose();
      setSelectedFile(null);

      return false;
    }
  };
  const handleExcelUpload = (event) => {
    event.preventDefault();
    setFormError(false);
    const file = event.target.files[0];
    console.log(file);

    setSelectedFile(file);
  };

  // const { open } = props;
  const handleClose = () => {
    onPopUpClose();
  };
  useEffect(() => {}, []);

  return (
    <Grid container>
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <h2 style={{ marginLeft: '10px' }}>Excel Import</h2>
              <IconButton onClick={handleClose}>X</IconButton>
            </Stack>
            <form id="loginForm" onSubmit={handleClick}>
              <Stack spacing={3}>
                <TextField type="file" name="excel" onChange={handleExcelUpload} />

                {formError && <FormHelperText error>This field is required.</FormHelperText>}

                <Stack alignItems="center" spacing={2}>
                  {loading ? (
                    <Stack alignItems="center" justifyContent="center">
                      <CircularProgress />
                    </Stack>
                  ) : (
                    <LoadingButton fullWidth size="large" type="submit" variant="contained">
                      Import data
                    </LoadingButton>
                  )}
                </Stack>
              </Stack>
            </form>
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
}
